import React, { useEffect, useState } from 'react';
import { Fab } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { keyframes } from '@mui/system';

// Define bounce animation
const bounceAnimation = keyframes`
  0%, 100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-5px);
  }
`;

const ScrollToTopButton = () => {
    const [showScroll, setShowScroll] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if (window.scrollY > 200) {
                setShowScroll(true);
            } else {
                setShowScroll(false);
            }
        };
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, []);

    const handleScrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        showScroll && (
            <Fab
                onClick={handleScrollToTop}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    backgroundColor: '#000',
                    color: '#fff',
                    '&:hover': {
                        backgroundColor: '#333',
                    },
                    width: 40, // Small size
                    height: 40,
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)',
                }}
                size=""
            >
                <KeyboardArrowUpIcon
                    sx={{
                        color: 'orange',
                        animation: `${bounceAnimation} 1s infinite`,
                    }}
                    fontSize="large"
                />
            </Fab>
        )
    );
};

export default ScrollToTopButton;

import { useState, useEffect } from 'react';
import axios from 'axios';

const Anualincome = ({ onTotalAmountChange }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [fetchedData, setFetchedData] = useState([]);
    const [damages, setDamages] = useState([]);
    const [fetchedPaymentData, setFetchedPaymentData] = useState([]);
    const [fetchedAllPaymentData, setFetchedAllPaymentData] = useState([]);


    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
            fetchDamages();
            fetchPaymentData();
            fetchPayments();
        }
    }, [user.id]);

    const fetchData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                const Order = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    && order.term === 'due on receipt'
                );
                setFetchedData(Order);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchPaymentData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            if (user.id) {
                const Order = response.data.filter(order =>
                    order.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
                    order.term !== 'due on receipt'
                );
                setFetchedPaymentData(Order);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    const fetchPayments = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-payment-data')
            if (user.id) {
                const Payment = response.data.filter(payment =>
                    payment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedAllPaymentData(Payment);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }

    };

    const fetchDamages = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            if (user.id) {
                const Damage = response.data.filter(damage =>
                    damage.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setDamages(Damage);
            }
        } catch (error) {
            console.error('Error fetching damage data:', error);
        }
    };

    useEffect(() => {
        const currentYear = new Date().getFullYear();

        let total = 0;
        fetchedData.forEach(order => {
            const createdAt = new Date(order.created_at);
            if (createdAt.getFullYear() === currentYear) {
                let orderTotal = 0;
                order.items.forEach(item => {
                    const itemPrice = item.price;
                    const itemTax = item.tax || 0;
                    const itemTotal = itemPrice + (itemPrice * (itemTax / 100)); // Adding item-specific tax
                    orderTotal += itemTotal;
                });

                const discountPercentage = order.discount || 0;
                const discountAmount = (orderTotal * discountPercentage) / 100; // Calculate discount amount
                orderTotal -= discountAmount; // Apply discount

                total += orderTotal;
            }
        });

let totalpay = 0;
fetchedPaymentData.forEach(order => {
            const createdAt = new Date(order.created_at);
            if (createdAt.getFullYear() === currentYear) {
                let orderTotal = 0;
                orderTotal += order.payamount || 0; // Add payamount directly
                
                totalpay += orderTotal;
            }
        });


        let totalallpayment = 0;
        fetchedAllPaymentData.forEach(payment => {
            const createdAt = new Date(payment.created_at);
            if (createdAt.getFullYear() === currentYear) {
                let orderTotalpayment = 0;

                orderTotalpayment += payment.amount || 0; // Add payamount directly
                
                totalallpayment += orderTotalpayment;
            }
        });
        

        const { damageTotalPrice } = damages.reduce(
            (totals, damage) => {
                damage.items.forEach(item => {
                    if (damage.type === 'money back') {
                        totals.damageTotalPrice += item.price;
                    }
                });
                return totals;
            },
            { damageTotalPrice: 0 }
        );

        const finalTotal = total - damageTotalPrice + totalpay + totalallpayment; // Deduct money back from total
        if (onTotalAmountChange) {
            onTotalAmountChange(finalTotal);
        }

       {/**  console.log('Money Back Deducted:', damageTotalPrice);*/}
    }, [fetchedData, damages, onTotalAmountChange, fetchedPaymentData, fetchedAllPaymentData]);

    return null; // No markup is returned
};

export default Anualincome;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    LinearProgress,
    IconButton,
    Collapse, TextField,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { keyframes } from '@mui/system';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import ScrollToTopButton from '../Button/ScrollToTopButton';
import HomeIcon from '@mui/icons-material/Home';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const OrderTable = () => {
    const [loading, setLoading] = useState(false);
    const [productTotals, setProductTotals] = useState({});
    const [productImages, setProductImages] = useState({});
    const [expandedCard, setExpandedCard] = useState(null);
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [searchTerm, setSearchTerm] = useState('');
    const [filteredProductTotals, setFilteredProductTotals] = useState({});
    const [showSearch, setShowSearch] = useState(false);

    const handleSearchClick = () => {
        setShowSearch((prev) => !prev);
    };

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                alert('Failed to fetch profile data!');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);


    const fetchOrderData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            const userId = user.id; // Ensure user.id is defined

            // Initialize the Orders variable
            let Orders = [];

            if (userId) {
                {/**console.log('User ID:', userId);*/}

                // Filter orders based on userId
                Orders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                // If userId is not available, use all orders
                Orders = response.data;
            }

            // Initialize totals object
            const totals = {};
            Orders.forEach(order => {
                order.items.forEach(item => {
                    const productName = item.product || 'Unknown Product';
                    if (!totals[productName]) {
                        totals[productName] = { sellTotal: 0, filledTotal: 0, emptyTotal: 0, damageTotal: 0, itemTotal: 0 };
                    }
                    totals[productName].sellTotal += item.quantity;
                });
            });

            return totals;
        } catch (error) {
            console.error('Error fetching order data:', error);
            return {};
        } finally {
            setLoading(false);
        }
    };

    const fetchStockData = async (existingTotals) => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-stock-data');
            const userId = user.id;

            let Orders = [];

            if (userId) {
                {/**console.log('User ID:', userId);*/}

                Orders = response.data.filter(stock =>
                    stock.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                Orders = response.data;
            }

            const totals = { ...existingTotals };
            Orders.forEach(stock => {
                stock.items.forEach(item => {
                    const productName = item.product ? item.product : 'Unknown Product';
                    if (!totals[productName]) {
                        totals[productName] = { sellTotal: 0, filledTotal: 0, emptyTotal: 0, damageTotal: 0, itemTotal: 0 };
                    }
                    if (item.status === 'Filled') {
                        totals[productName].filledTotal += item.quantity;
                    } else if (item.status === 'Empty') {
                        totals[productName].emptyTotal += item.quantity;
                    } else if (item.status === 'item') {
                        totals[productName].itemTotal += item.quantity;
                    }
                });
            });
            return totals;
        } catch (error) {
            console.error('Error fetching stock data:', error);
            return existingTotals;
        } finally {
            setLoading(false);
        }
    };

    const fetchDamageData = async (existingTotals) => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            const userId = user.id; // Ensure user.id is defined

            // Initialize the Orders variable
            let Orders = [];

            if (userId) {
                {/**console.log('User ID:', userId);*/}

                // Filter orders based on userId
                Orders = response.data.filter(damage =>
                    damage.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                // If userId is not available, use all orders
                Orders = response.data;
            }
            const totals = { ...existingTotals };
            Orders.forEach(order => {
                order.items.forEach(item => {
                    const productName = item.product ? item.product : 'Unknown Product';
                    if (!totals[productName]) {
                        totals[productName] = { sellTotal: 0, filledTotal: 0, emptyTotal: 0, damageTotal: 0, ItemTotal: 0 };
                    }

                    if (order.type === 'replace') {
                        totals[productName].sellTotal += item.quantity;
                        totals[productName].damageTotal += item.quantity;
                    } else {
                        totals[productName].damageTotal += item.quantity;
                    }
                });
            });

            return totals;
        } catch (error) {
            console.error('Error fetching damage data:', error);
            return existingTotals;
        } finally {
            setLoading(false);
        }
    };


    const fetchProductImages = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-product-data');
            const data = response.data.products;

            const images = {};
            data.forEach(product => {
                if (product.product && product.image) {
                    images[product.product] = `https://admin.bizstor.lk/storage/app/public/${product.image}`;
                }
            });
            setProductImages(images);
        } catch (error) {
            console.error('Error fetching product images:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        const orderTotals = await fetchOrderData();
        const stockTotals = await fetchStockData(orderTotals);
        const damageTotals = await fetchDamageData(stockTotals);
        setProductTotals(damageTotals);
        await fetchProductImages();
    };

    const filterData = (term) => {
        const filtered = Object.keys(productTotals)
            .filter(product => product.toLowerCase().includes(term.toLowerCase())) // Filter by product name
            .reduce((acc, key) => {
                acc[key] = productTotals[key];
                return acc;
            }, {});
        setFilteredProductTotals(filtered);
    };

    useEffect(() => {
        filterData(searchTerm);
    }, [productTotals, searchTerm]);

    useEffect(() => {
        fetchData();
    }, []);

    const handleExpandClick = (product) => {
        setExpandedCard(expandedCard === product ? null : product);
    };


    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value); // Update search term on input change
    };

    return (
        <Box>
                        <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Inventory Details
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Inventory Details
                    </Typography>
                </Box>
            </AnimatedTypography>

           { /**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Inventory Detail
            </AnimatedTypography>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    marginBottom: '30px',
                    bgcolor: 'background.paper',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    borderRadius: '5px',
                    padding: '10px',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}
                <Typography variant="h4" component="h1" gutterBottom
                    sx={{
                        mb: 3,
                        ml: 2
                    }}>
                    
                </Typography>
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                            ml:2
                        }}
                    >
                        <h2>INVENTORY DETAILS</h2>
                    </Box>

                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search…"
                    fullWidth
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        ),
                    }}
                    sx={{ marginBottom: '20px', width: '250px', ml: 2,
'@media (max-width: 600px)': {
                              margin: 'auto',
                              marginBottom: '20px',
                            },

                     }}
                />

                {/**  <Box>
            <IconButton 
            onClick={handleSearchClick}
            sx={{
                mb:1
            }}
            >
                <SearchIcon />
            </IconButton>
            <Collapse in={showSearch} timeout="auto">
                <TextField
                    variant="outlined"
                    margin="normal"
                    size="small"
                    value={searchTerm}
                    onChange={handleSearchChange}
                    placeholder="Search…"
                    fullWidth
                    sx={{
                        marginBottom: '20px',
                        width: '250px',
                        ml: 2,
                        transition: 'width 0.3s ease',
                    }}
                />
            </Collapse>
        </Box>*/}

                <Grid container spacing={3}>
                    {Object.keys(filteredProductTotals).map(product => (
                        <Grid item xs={12} sm={6} md={3} >
                            <Card
                                sx={{
                                    maxWidth: '100%',
                                    borderRadius: '12px',
                                    ml: 1,
                                    mr: 1,
                                    mb: 2,
                                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                                    transition: 'transform 0.3s, box-shadow 0.3s',
                                    '&:hover': {
                                        transform: 'scale(1.05)',
                                        boxShadow: '0 8px 30px rgba(0, 0, 0, 0.2)',
                                    },
                                }}
                            >
                                <CardHeader
                                    title={product}
                                    sx={{
                                        backgroundColor: 'orange',
                                        color: 'white',
                                        textAlign: 'center',
                                        borderTopLeftRadius: '8px',
                                        borderTopRightRadius: '8px',
                                        fontSize: {
                                            xs: '0.75rem', // Smaller font size for extra-small screens
                                            sm: '0.875rem', // Slightly larger font size for small screens
                                        },
                                    }}
                                />
                                <CardContent
                                    sx={{
                                        display: 'flex',
                                        flexDirection: {
                                            xs: 'column', // Stack items vertically on extra-small screens
                                            sm: 'row',    // Align items horizontally on small screens and up
                                        },
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        padding: {
                                            xs: '8px', // Smaller padding for extra-small screens
                                            sm: '12px', // Default padding for small screens and up
                                        },
                                        backgroundColor: '#f9f9f9',
                                    }}
                                >
                                    <Box sx={{ fontSize: '0.65rem', color: 'text.secondary' }}> {/* Wrapper to avoid nesting Typography */}
                                        {productTotals[product]?.itemTotal != null && productTotals[product]?.itemTotal > 0 && (
                                            <Typography variant="body2" sx={{ fontSize: '0.75rem', color: (productTotals[product].itemTotal - productTotals[product]?.sellTotal || 0) <= 0 ? 'red' : 'inherit' }}>
                                                {(productTotals[product].itemTotal - productTotals[product]?.sellTotal || 0) > 0
                                                    ? `In Stock: ${productTotals[product].itemTotal - productTotals[product]?.sellTotal}`
                                                    : 'Out of Stock'
                                                }
                                            </Typography>

                                        )}

                                        {productTotals[product]?.filledTotal != null && productTotals[product]?.filledTotal > 0 && (
                                            <Typography
                                                variant="body2"
                                                sx={{
                                                    fontSize: '0.75rem',
                                                    color: (productTotals[product].filledTotal - productTotals[product]?.sellTotal || 0) <= 0 ? 'red' : 'inherit'
                                                }}
                                            >
                                                {(productTotals[product].filledTotal - productTotals[product]?.sellTotal || 0) > 0
                                                    ? `In Stock: ${productTotals[product].filledTotal - productTotals[product]?.sellTotal}`
                                                    : 'Out of Stock'
                                                }
                                            </Typography>
                                        )}
                                    </Box>

                                    <Box
                                        component="img"
                                        sx={{
                                            height: {
                                                xs: 40, // Smaller image size for extra-small screens
                                                sm: 50, // Default image size for small screens and up
                                            },
                                            width: {
                                                xs: 40, // Smaller image size for extra-small screens
                                                sm: 50, // Default image size for small screens and up
                                            },
                                            borderRadius: '8px',
                                            objectFit: 'cover',
                                            ml: 2,
                                            boxShadow: '0 2px 8px rgba(0, 0, 0, 0.1)',
                                        }}
                                        src={productImages[product] || "https://via.placeholder.com/50"}
                                        alt={product}
                                    />
                                </CardContent>
                                <CardActions
                                    disableSpacing
                                    sx={{
                                        justifyContent: 'center',
                                        backgroundColor: '#f5f5f5',
                                        borderBottomLeftRadius: '8px',
                                        borderBottomRightRadius: '8px',
                                    }}
                                >
                                    <ExpandMore
                                        expand={expandedCard === product}
                                        onClick={() => handleExpandClick(product)}
                                        aria-expanded={expandedCard === product}
                                        aria-label="show more"
                                        sx={{
                                            transform: expandedCard === product ? 'rotate(180deg)' : 'rotate(0deg)',
                                            transition: 'transform 0.3s',
                                        }}
                                    >
                                        <ExpandMoreIcon />
                                    </ExpandMore>
                                </CardActions>
                                <Collapse in={expandedCard === product} timeout="auto" unmountOnExit>
                                    <CardContent>
                                        <Typography paragraph sx={{ fontSize: '0.75rem' }}><b>Details</b></Typography>

                                        {productTotals[product] ? (
                                            <>
                                                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                                    Total Sell Qty: {productTotals[product].sellTotal}
                                                </Typography>

                                                {productTotals[product].itemTotal != null && productTotals[product].itemTotal > 0 && (
                                                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                                        Total Item Qty: {productTotals[product].itemTotal - productTotals[product].sellTotal || 0}
                                                    </Typography>
                                                )}

                                                {productTotals[product].filledTotal != null && productTotals[product].filledTotal > 0 && (
                                                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                                        Total Filled Qty: {productTotals[product].filledTotal - productTotals[product].sellTotal || 0}
                                                    </Typography>
                                                )}

                                                {productTotals[product].emptyTotal != null && productTotals[product].emptyTotal > 0 && (
                                                    <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                                        Total Empty Qty: {productTotals[product].emptyTotal - productTotals[product].filledTotal || 0}
                                                    </Typography>
                                                )}

                                                <Typography variant="body2" sx={{ fontSize: '0.75rem' }}>
                                                    Total Return Qty: {productTotals[product].damageTotal}
                                                </Typography>

                                                <Box sx={{ width: '100%', mt: 2 }}>
                                                    <LinearProgress
                                                        variant="determinate"
                                                        value={(productTotals[product].filledTotal /
                                                            (productTotals[product].filledTotal + productTotals[product].sellTotal)) * 100}
                                                        sx={{
                                                            height: 8,
                                                            borderRadius: 4,
                                                            '& .MuiLinearProgress-bar': {
                                                                backgroundColor: 'orange',
                                                            },
                                                        }}
                                                    />
                                                </Box>
                                            </>
                                        ) : (
                                            <Typography variant="body2" sx={{ fontSize: '0.75rem', color: 'red' }}>
                                                No data available for this product.
                                            </Typography>
                                        )}
                                    </CardContent>
                                </Collapse>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Box>
            <ScrollToTopButton/>
        </Box>
    );
};

export default OrderTable;

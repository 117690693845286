import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Typography,
    Select,
    MenuItem,
    Snackbar,
    FormControl,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { styled, keyframes } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import { Tooltip } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const Stock = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [rows, setRows] = useState([{ product: '', bottleStatus: '', quantity: '' }]);
    const [branch, setBranch] = useState('');
    const [date, setDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [fetchedData, setFetchedData] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };



    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchProducts();
        }
    }, [user.id]);

    const handleAddRow = () => {
        setRows([...rows, { product: '', bottleStatus: '', quantity: '' }]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, i) => {
            if (i === index) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(newRows);
    };

    const fetchData = async () => {
        setLoading(true); // Start loading

        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            setFetchedData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleSubmit = async () => {
        setLoading(true); // Start loading

        const hasInvalidRow = rows.some(row => !row.product || !row.quantity);

        if (hasInvalidRow) {
            handleSnackbar('Please fill in all required product and quantity fields.', 'error');
            setLoading(false);
            return;
        }

        try {
            const data = {
                usertoken: user.id,
                branch: branch,
                date: date || new Date().toISOString().split('T')[0],
                items: rows.map(row => ({
                    product: row.product,
                    quantity: row.quantity,
                    status: "item"
                }))
            };

            {/**console.log('Data to be sent:', data);*/}

            // Send data to server
            const response = await axios.post('https://admin.bizstor.lk/public/api/save-stock-data', data);

            // Clear input fields after successful save
            setBranch('');
            setDate('');
            setRows([{ product: '', quantity: '', status: '' }]); // Reset rows to initial state

            // Show alert on success
            handleSnackbar('Inventory save successfully!', 'success');
            setLoading(false); // Stop loading
            // Fetch data after saving
            fetchData();
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const [products, setProducts] = useState([]);

    const fetchProducts = () => {
        setLoading(true);
        axios.get('https://admin.bizstor.lk/public/api/get-product-data')
            .then(response => {
                if (user.id) {
                    {/**console.log('console.log("");:', user.id);*/}
                    const Products = response.data.products.filter(product =>
                        product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setProducts(Products);
                }
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false);
            });
    };

    const productPrices = {};
    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Items
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Inventory - Items
                    </Typography>
                </Box>
            </AnimatedTypography>
            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Inventory / Items
            </AnimatedTypography> */}
            <Box
           sx={{     display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative',
                    '@media (max-width: 600px)': {
                      marginTop: '10px',
                            },
      }}
            >

                <Typography variant="h5"
                    sx={{
                        ml: 2
                    }}
                >

                </Typography>
                  {/**{/**<hr style={{ borderTop: '1px solid #6d7081', width: '100%', margin: '10px 0' }} />*/}
                  <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                            ml:2
                        }}
                    >
                        <h2>ITEMS</h2>
                    </Box> 
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                {/**  <TableCell>
                                    <TextField
                                        label="Branch"
                                        value={branch}
                                        onChange={(e) => setBranch(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                    //disabled
                                    />
                                </TableCell> */}
                                <TableCell>
                                    <TextField
                                        type="date"
                                        value={date || new Date().toISOString().split('T')[0]}
                                        onChange={(e) => setDate(e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        sx={{
                                            width: '280px',
                                            justifyContent: 'center',
                                        }}
                                    />
                                    <Tooltip title="This is used to store the items. Before adding items, ensure the product's buying price is added. If the buying price is not added, please add it before adding items." arrow>
                                        <IconButton>
                                            <HelpOutlineIcon />
                                        </IconButton>
                                    </Tooltip>
                                </TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>


                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'right',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                        textAlign: 'right',
                    }}
                >
                    <Typography variant="subtitle1">
                        TOTAL QTY
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                        {rows.reduce((total, row) => total + parseInt(row.quantity, 10), 0) || 0}
                    </Typography>
                </Box>


                {/**     <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    padding: '10px',
                    marginTop: '10px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '1.25rem', // Adjust size as needed
                    }}
                >
                    Total Qty {rows.reduce((total, row) => total + parseInt(row.quantity, 10), 0)}
                </Typography>
            </Box>*/}



                <TableContainer
                    component={Paper}
                    style={{
                        marginTop: '20px',
                        boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)',
                        width: '100%',
                        overflowX: 'auto', // Enable horizontal scrolling on small screens
                    }}
                >
                    <Table style={{ minWidth: 650 }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Product</TableCell>
                                <TableCell>Qty</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <FormControl fullWidth>
                                            <Select
                                                value={row.product}
                                                onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                                size="small"
                                                sx={{ width: '400px',
                                                    '@media (max-width: 600px)': {
                                                width: '250px',
                                            },
                                                 }} // Fixed width for product select
                                            >
                                                {Object.keys(productPrices).map((product) => (
                                                    <MenuItem key={product} value={product}>
                                                        {product}
                                                    </MenuItem>
                                                ))}
                                                <MenuItem value="">
                                                    <Link to="/addproduct" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        Add New Product
                                                    </Link>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            type="number"
                                            value={row.quantity}
                                            onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                            variant="outlined"
                                            size="small"
                                            sx={{ width: '150px' }} // Fixed width for quantity input
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteRow(index)} aria-label="delete" color="inherit">
                                            <DeleteIcon
                                                sx={{
                                                    '&:hover': {
                                                        color: 'red',
                                                    },
                                                }}
                                            />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>

                    </Table>
                </TableContainer>

                <Box display="flex" justifyContent="flex-start" marginTop="15px">
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleAddRow}
                        style={{ backgroundColor: 'black', color: 'white' }}
                    >
                        <PlaylistAddIcon />
                    </Button>
                </Box>





                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#4287f5',
                            color: 'white',
                            marginTop: '30px',
                            borderRadius: '20px',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#356bc4',
                            },
                            marginBottom: { xs: '8px', sm: '0' }
                        }}
                    >
                        Submit
                    </Button>
                </div>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white',
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default Stock;

import React, { useState } from 'react';
import axios from 'axios';
import {
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    Box,
    TextField,
    Button,
    CircularProgress,
    Snackbar
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddCustomer = ({ open, handleClose }) => {
    const [firstname, setFirstName] = useState('');
    const [lastname, setLastName] = useState('');
    const [email, setEmail] = useState('');
    const [address, setAddress] = useState('');
    const [phone, setPhone] = useState('');
    const [nic, setNic] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);

    // Function to handle form submission
    const handleSubmit = async () => {
        setLoading(true); // Start loading

        try {
            const data = {
                firstname: firstname,
                lastname: lastname,
                email: email,
                address: address,
                phone: phone,
                nic: nic,
            };

            {/**console.log('Data to be sent:', data);*/}

            // Send data to server
            const response = await axios.post('https://admin.bizstor.lk/public/api/save-customer-data', data);
            {/**console.log('Data saved successfully:', response.data);*/}

            // Clear input fields after successful save
            setFirstName('');
            setLastName('');
            setEmail('');
            setAddress('');
            setPhone('');
            setNic('');

            // Show alert on success
            setAlertOpen(true);
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false); // Stop loading
            handleClose(); // Close the dialog
        }
    };

    // Function to handle text field changes
    const handleFirstNameChange = (e) => {
        setFirstName(e.target.value);
    };

    const handleLastNameChange = (e) => {
        setLastName(e.target.value);
    };

    const handleEmailChange = (e) => {
        setEmail(e.target.value);
    };

    const handleAddressChange = (e) => {
        setAddress(e.target.value);
    };

    const handlePhoneChange = (e) => {
        setPhone(e.target.value);
    };

    const handleNicChange = (e) => {
        setNic(e.target.value);
    };

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor: 'black' }}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Add New Customer
                    </Typography>
                </Toolbar>
            </AppBar>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '100%',
                    maxWidth: '600px',
                    margin: '20px',
                    padding: '20px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                }}
            >
                <Typography variant="h5" gutterBottom>
                    Add Customer
                </Typography>

                <TextField
                    label="First Name"
                    value={firstname}
                    onChange={handleFirstNameChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: '15px' }}
                    autoFocus // Ensure focus on first field
                />
                <TextField
                    label="Last Name"
                    value={lastname}
                    onChange={handleLastNameChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: '15px' }}
                />
                <TextField
                    label="Email"
                    value={email}
                    onChange={handleEmailChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: '15px' }}
                />
                <TextField
                    label="Address"
                    value={address}
                    onChange={handleAddressChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: '15px' }}
                />
                <TextField
                    label="Phone"
                    value={phone}
                    onChange={handlePhoneChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: '15px' }}
                />
                <TextField
                    label="NIC No"
                    value={nic}
                    onChange={handleNicChange}
                    variant="outlined"
                    fullWidth
                    size="small"
                    sx={{ marginBottom: '15px' }}
                />

                <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        disabled={loading} // Disable button while loading
                    >
                        {loading ? <CircularProgress size={24} color="inherit" /> : 'Add Customer'}
                    </Button>
                </Box>

                <Snackbar
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={() => setAlertOpen(false)}
                    message="Saved successfully!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </Box>
        </Dialog>
    );
};

export default AddCustomer;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Card,
    CardContent,
    Typography,
    Grid,
    LinearProgress,
    IconButton,
    Collapse,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import Chart from '../Home/Chart';


const ExpandMore = styled((props) => {
    const { expand, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    }),
}));

const OrderTable = () => {
    const [loading, setLoading] = useState(false);
    const [productTotals, setProductTotals] = useState({});
    const [productImages, setProductImages] = useState({});
    const [expandedCard, setExpandedCard] = useState(null);

    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);


    const fetchOrderData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            const userId = user.id; // Ensure user.id is defined

            // Initialize the nisalOrders variable
            let nisalOrders = [];

            if (userId) {
                {/**console.log('console.log("");:', userId);*/}

                // Filter orders based on userId
                nisalOrders = response.data.filter(order =>
                    order.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                // If userId is not available, use all orders
                nisalOrders = response.data;
            }

            // Initialize totals object
            const totals = {};
            nisalOrders.forEach(order => {
                order.items.forEach(item => {
                    const productName = item.product || 'Unknown Product';
                    if (!totals[productName]) {
                        totals[productName] = { sellTotal: 0, filledTotal: 0, emptyTotal: 0, damageTotal: 0 };
                    }
                    totals[productName].sellTotal += item.quantity;
                });
            });

            return totals;
        } catch (error) {
            console.error('Error fetching order data:', error);
            return {};
        } finally {
            setLoading(false);
        }
    };

    const fetchStockData = async (existingTotals) => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-stock-data');
            const userId = user.id;

            let Orders = [];

            if (userId) {
                {/**console.log('console.log("");:', userId);*/}

                Orders = response.data.filter(stock =>
                    stock.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                Orders = response.data;
            }

            const totals = { ...existingTotals };
            Orders.forEach(stock => {
                stock.items.forEach(item => {
                    const productName = item.product ? item.product : 'Unknown Product';
                    if (!totals[productName]) {
                        totals[productName] = { sellTotal: 0, filledTotal: 0, emptyTotal: 0, damageTotal: 0, ItemTotal: 0 };
                    }
                    if (item.status === 'Filled') {
                        totals[productName].filledTotal += item.quantity;
                    } else if (item.status === 'Empty') {
                        totals[productName].emptyTotal += item.quantity;
                    } else if (item.status === 'item') {
                        totals[productName].filledTotal += item.quantity;  // Treat "New" as "In Stock"
                    }
                });
            });
            return totals;
        } catch (error) {
            console.error('Error fetching stock data:', error);
            return existingTotals;
        } finally {
            setLoading(false);
        }
    };

    const fetchDamageData = async (existingTotals) => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
            const userId = user.id; // Ensure user.id is defined

            // Initialize the nisalOrders variable
            let nisalOrders = [];

            if (userId) {
                {/**console.log('console.log("");:', userId);*/}

                // Filter orders based on userId
                nisalOrders = response.data.filter(damage =>
                    damage.usertoken.toLowerCase() === userId.toString().toLowerCase()
                );
            } else {
                // If userId is not available, use all orders
                nisalOrders = response.data;
            }
            const totals = { ...existingTotals };
            nisalOrders.forEach(order => {
                order.items.forEach(item => {
                    const productName = item.product ? item.product : 'Unknown Product';
                    if (!totals[productName]) {
                        totals[productName] = { sellTotal: 0, filledTotal: 0, emptyTotal: 0, damageTotal: 0 };
                    }

                    if (order.type === 'replace') {
                        totals[productName].sellTotal += item.quantity;
                        totals[productName].damageTotal += item.quantity;
                    } else {
                        totals[productName].damageTotal += item.quantity;
                    }
                });
            });

            return totals;
        } catch (error) {
            console.error('Error fetching damage data:', error);
            return existingTotals;
        } finally {
            setLoading(false);
        }
    };


    const fetchProductImages = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-product-data');
            const data = response.data.products;

            const images = {};
            data.forEach(product => {
                if (product.product && product.image) {
                    images[product.product] = `https://admin.bizstor.lk/storage/app/public/${product.image}`;
                }
            });
            setProductImages(images);
        } catch (error) {
            console.error('Error fetching product images:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchData = async () => {
        const orderTotals = await fetchOrderData();
        const stockTotals = await fetchStockData(orderTotals);
        const damageTotals = await fetchDamageData(stockTotals);
        setProductTotals(damageTotals);
        await fetchProductImages();
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleExpandClick = (product) => {
        setExpandedCard(expandedCard === product ? null : product);
    };

    return (
        <Box sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            maxWidth: 'auto',
            margin: 'auto',
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            padding: '10px',
            borderRadius: '5px',
            bgcolor: 'background.paper',
            marginTop: '30px',
            position: 'relative',
            mb: 3
        }}>
            <Grid container spacing={3}>
                {/* First Box */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',

                            maxWidth: 'auto',
                            margin: 'auto',
                            marginBottom: '30px',
                            padding: '10px',
                            marginTop: '30px',
                            position: 'relative',
                        }}
                    >
                        {loading && (
                            <Box sx={{
                                position: 'fixed',
                                top: 0,
                                left: 0,
                                width: '100vw',
                                height: '100vh',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                bgcolor: 'white',
                                zIndex: 9999,
                            }}>
                                <l-trio
                                    size="50"
                                    speed="1.3"
                                    color="red"
                                ></l-trio>
                            </Box>
                        )}
                        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 3 }}>
                            Inventory
                        </Typography>

                        <Grid container spacing={3}>
                            {Object.keys(productTotals).map((product, idx) => (
                                <Grid item xs={12} sm={6} md={3} key={idx}>
                                <Typography variant="body1" sx={{ fontWeight: 'bold', color: 'black' }}>
                                    {product}
                                </Typography>
                                <Typography variant="body2" sx={{ color: 'green' }}>
                                    In Stock: {productTotals[product].filledTotal - productTotals[product].sellTotal}
                                </Typography>
                                {productTotals[product].filledTotal - productTotals[product].sellTotal <= 0 ? (
                                    <Typography variant="body2" sx={{ color: 'red' }}>
                                        Out of Stock
                                    </Typography>
                                ) : (
                                    <Box sx={{ width: '100%', mt: 1 }}>
                                        <LinearProgress
                                            variant="determinate"
                                            value={(productTotals[product].filledTotal / (productTotals[product].filledTotal + productTotals[product].sellTotal)) * 100}
                                            sx={{
                                                height: 8, // Progress bar height
                                                borderRadius: 4,
                                                '& .MuiLinearProgress-bar': {
                                                    backgroundColor: 'orange',
                                                },
                                            }}
                                        />
                                    </Box>
                                )}
                            </Grid>
                            
                            ))}
                        </Grid>
                    </Box>
                </Grid>

                {/* Second Box */}
                <Grid item xs={12} md={6}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            maxWidth: 'auto',
                            margin: 'auto',
                            marginBottom: '30px',
                            padding: '10px',
                            marginTop: '30px',
                            position: 'relative',
                            // bgcolor: '#f0f0f0', 
                        }}
                    >
                        <Typography variant="h4" component="h1" gutterBottom sx={{ mb: 3 }}>

                        </Typography>
                        <Chart />
                    </Box>
                </Grid>
            </Grid>
        </Box>
    );
};

export default OrderTable;

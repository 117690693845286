import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Typography,
    Select,
    MenuItem,
    CircularProgress,
    Snackbar,
    FormControl,
    InputLabel,
    ListSubheader
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { quantum, trio } from 'ldrs';
import { styled, keyframes } from '@mui/system';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
  }));
  
quantum.register();
trio.register();

const Expenses = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [rows, setRows] = useState([{ category: '', amount: '' }]);
    const [fullname, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; 

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);
    

    const handleAddRow = () => {
        setRows([...rows, { category: '', amount: '' }]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, i) => {
            if (i === index) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(newRows);
    };

    const fetchData = () => {
        setLoading(true);

        axios.get('https://admin.bizstor.lk/public/api/get-expenses-category-data')
            .then(response => {
                setExpenses(response.data.expenses);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false);
            });

        axios.get('https://admin.bizstor.lk/public/api/get-customer-data')
            .then(response => {
                setCustomers(response.data.customers || []);
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching customer data:', error);
                setLoading(false);
            });
    };

    useEffect(() => {
        setLoading(true);

        const fetchSuppliers = async () => {
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/suppliers');
                setSuppliers(response.data);
                setLoading(false);
            } catch (error) {
                console.error('Error fetching suppliers:', error);
                setLoading(false);
            }
        };

        fetchSuppliers();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;

        // Find if the selected value is a customer or supplier
        const selectedCustomer = customers.find(customer => customer.firstname === selectedValue);
        const selectedSupplier = suppliers.find(supplier => supplier.supplier_name === selectedValue);

        if (selectedCustomer) {
            setFullName(selectedCustomer.firstname);
            setPhone(selectedCustomer.phone || ''); // Set default last name if available
        } else if (selectedSupplier) {
            setFullName(selectedSupplier.supplier_name);
            setPhone(''); // Supplier may not have a last name
        }

        setType(selectedValue);
    };

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const data = {
                usertoken: user.id,
                fullname: fullname,
                phone: phone,
                type: type,
                date: date,
                description: description,
                items: rows.map(row => ({
                    category: row.category,
                    amount: row.amount,
                }))
            };

            {/**console.log('Data to be sent:', data);*/}

            const response = await axios.post('https://admin.bizstor.lk/public/api/save-expense-data', data);
            {/**console.log('Data saved successfully:', response.data);*/}

            // Clear input fields after successful save
            setFullName('');
            setPhone('');
            setType('');
            setDate('');
            setDescription('');
            setRows([{ category: '', amount: '' }]);

            // Show alert on success
            setAlertOpen(true);

            // Fetch data after saving
            fetchData();
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: 5,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em' // Adjust letter spacing as needed
                }}
            >
                Dashboard / Expenses
            </AnimatedTypography>
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'column',
            width: '95%',
            maxWidth: 'auto',
            margin: 'auto',
            boxShadow: 1,
            padding: '10px',
            borderRadius: '5px',
            bgcolor: 'background.paper',
            marginTop: '30px',
            position: 'relative'
          }}
        >
            <Typography variant="h5"
            sx={{
                ml:2
            }}
            >
                Expenses
            </Typography>
            <hr style={{ borderTop: '1px solid orange', width: '100%', margin: '10px 0' }} />

            <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell sx={{ width: '25%' }}>
                                <FormControl variant="outlined" fullWidth size="small">
                                    <InputLabel >Select Person</InputLabel>
                                    <Select
                                        value={type}
                                        onChange={handleSelectChange}
                                        label="Select Person"
                                        fullWidth
                                    >
                                        <ListSubheader>Customers</ListSubheader>
                                        {customers.length > 0 && customers.map(customer => (
                                            <MenuItem key={customer.id} value={customer.firstname}>
                                                {customer.firstname}
                                            </MenuItem>
                                        ))}
                                        <ListSubheader>Suppliers</ListSubheader>
                                        {suppliers.map(supplier => (
                                            <MenuItem key={supplier.id} value={supplier.supplier_name}>
                                                {supplier.supplier_name}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell sx={{ width: '25%' }}>
                                <TextField
                                    label="Full Name"
                                    value={fullname}
                                    onChange={(e) => setFullName(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </TableCell>
                            <TableCell sx={{ width: '25%' }}>
                                <TextField
                                    label="Phone"
                                    value={phone}
                                    onChange={(e) => setPhone(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                />
                            </TableCell>
                            <TableCell sx={{ width: '25%' }}>
                                <TextField
                                    type="date"
                                    label="Date"
                                    value={date}
                                    onChange={(e) => setDate(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <TextField
                                    label="Description"
                                    value={description}
                                    onChange={(e) => setDescription(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    multiline
                                    minRows={4}
                                    sx={{ width: '50%' }}
                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>

            <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '10px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleAddRow}
                    style={{ backgroundColor: 'black', color: 'white' }}
                >
                    Add Lines
                </Button>
            </div>

            <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={index}>
                                <TableCell>{index + 1}</TableCell>
                                <TableCell>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel>Category</InputLabel>
                                        <Select
                                            value={row.category}
                                            onChange={(e) => handleInputChange(index, 'category', e.target.value)}
                                            label="Category"
                                            fullWidth
                                        >
                                            {expenses.length > 0 && expenses.map(expense => (
                                                <MenuItem key={expense.id} value={expense.expenses}>
                                                    {expense.expenses}
                                                </MenuItem>
                                            ))}
                                            <hr></hr>
                                            <MenuItem value="">
                                                <Link to="/addexpenses" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                    Add New Expense
                                                </Link>
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        label="Amount"
                                        value={row.amount}
                                        onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                        variant="outlined"
                                        fullWidth
                                        size="small"
                                        inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                    />
                                </TableCell>
                                <TableCell>
                                    <IconButton onClick={() => handleDeleteRow(index)}>
                                        <DeleteIcon 
                                        sx={{
                                            '&:hover': {
                                                color: 'red',
                                            },
                                        }}/>
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    padding: '10px',
                    marginTop: '10px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '1.25rem', // Adjust size as needed
                    }}
                >
                    Total Amount: LKR {rows.reduce((sum, row) => sum + parseFloat(row.amount || 0), 0).toFixed(2)}
                </Typography>
            </Box>

            <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={handleSubmit}
                    style={{ backgroundColor: 'black', color: 'white' }}
                    value='save'

                >
                    Submit
                </Button>
            </Box>

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white', // Optional: to give a slightly white overlay
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}

            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                message="Data saved successfully!"
            />
        </Box>
        </Box>
    );
};

export default Expenses;

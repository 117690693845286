import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom';
import {
    TextField,
    Button,
    Box,
    Typography,
    Snackbar,
    Grid,
    Alert,
    TableCell,
    FormControl,
    InputLabel,
    Select, MenuItem
} from '@mui/material';
import { quantum, trio } from 'ldrs';
import { styled, keyframes } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

quantum.register();
trio.register();

const AddInvestment = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [amount, setAmount] = useState('');
    const [date, setDate] = useState('');
    const [to, setTo] = useState('');
    const [from, setFrom] = useState('');
    const [person, setPerson] = useState('');
    const [description, setDescription] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [fetchedData, setFetchedData] = useState([]);
    const [investments, setInvestments] = useState([]);
    const [totalAmount, setTotalAmount] = useState(0);
    const [totalBankAmount, setTotalBankAmount] = useState(0);
    const [error, setError] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [selectedSupplier, setSelectedSupplier] = useState('');

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);


    const handleSubmit = async () => {
        setLoading(true); // Start loading

        const hasInvalidType = !to || !from;

        if (hasInvalidType) {
            handleSnackbar('Please select the transfer from & transfer to.', 'error');
            setLoading(false);
            return;
        }

        const hasInvalidPerson = !selectedSupplier;

        if (hasInvalidPerson) {
            handleSnackbar('Please select the person.', 'error');
            setLoading(false);
            return;
        }

        const hasInvalidAmount = !amount;

        if (hasInvalidAmount) {
            handleSnackbar('Please enter the amount.', 'error');
            setLoading(false);
            return;
        }

        try {
            const data = {
                usertoken: user.id,
                to: to,
                from: from,
                person: selectedSupplier,
                amount: amount,
                date: date || new Date().toISOString().split('T')[0],
                description: description,

            };


            // Send data to server
            const response = await axios.post('https://admin.bizstor.lk/public/api/save-investment', data);

            // Clear input fields after successful save
            setTo('');
            setFrom('');
            setPerson('');
            setAmount('');
            setDate('');
            setDescription('');

            // Show alert on success
            handleSnackbar('Investment added successfully!', 'success');
            setLoading(false); // Stop loading

        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const fetchData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-investment');
            if (user.id) {
                const Investment = response.data.filter(investment =>
                    investment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedData(Investment);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {

        const fetchInvestments = async () => {
            try {
                // Make the API request
                const response = await axios.get('https://admin.bizstor.lk/public/api/get-investment');
                let data = response.data.investment; // Fetching investment data

                if (user && user.id) { // Ensure user and user.id are defined

                    // Filter investments based on user.id
                    data = data.filter(investment =>
                        investment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                }

                // Filter investments where 'to' is 'bank' and calculate the total amount
                const bankInvestments = data.filter(investment => investment.to === 'bank');
                const totalAmount = bankInvestments.reduce((acc, investment) => acc + parseFloat(investment.amount), 0);

                // Subtract the amounts if 'from' is 'capital' or 'credit'
                const debitInvestments = data.filter(investment => investment.from === 'bank');
                const totalDebitAmount = debitInvestments.reduce((acc, investment) => acc + parseFloat(investment.amount), 0);

                // Update total amount by subtracting the total debit amount
                const adjustedTotal = totalAmount - totalDebitAmount;

                // Update the state with filtered investments and calculated total
                setInvestments(data);
                setTotalBankAmount(adjustedTotal);
            } catch (err) {
                setError(err.message);
            } finally {
                setLoading(false);
            }
        };

        // Call the function to fetch data on component mount
        fetchInvestments();
    }, [user]);

    const fetchSuppliers = async () => {
        try {
            setLoading(true);
            const response = await axios.get('https://admin.bizstor.lk/public/api/suppliers');
            console.log(response.data); // Log the response to check the structure
            if (user.id) {
                const filteredSuppliers = response.data.filter(supplier =>
                    supplier.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setSuppliers(filteredSuppliers);
            }
        } catch (error) {
            console.error('Error fetching suppliers:', error);
        } finally {
            setLoading(false);
        }
    };


    useEffect(() => {
        fetchSuppliers();
    }, [user]);


    return (
        <Box>
            <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Investment
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Investment 
                    </Typography>
                </Box>
            </AnimatedTypography>
            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',      // Adjust font size as needed
                    color: 'secondary.main', // Use theme color or specify a color like '#333'
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Investment
            </AnimatedTypography>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    mb: 3,
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative'
                }}
            >
                <Typography variant="h5">
                    
                </Typography>
                {/**<hr style={{ borderTop: '1px solid #6d7081', width: '100%', margin: '10px 0' }} />*/}
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                            ml:2
                        }}
                    >
                        <h2>INVESTMENT</h2>
                    </Box> 

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: { xs: 'column', md: 'row' }, // Stack on small screens, side by side on larger screens
                        justifyContent: 'space-between',
                        padding: '20px',
                    }}
                >
                    {/* Left Side: Form Inputs */}

                    <Grid container spacing={2} item xs={12} md={6}>

                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="from-label">Transfer From</InputLabel>
                                <Select
                                    labelId="from-label"
                                    id="transfer-from"
                                    value={from}
                                    onChange={(e) => setFrom(e.target.value)}
                                    label="Transfer From"
                                    sx={{ mb: 1 }}
                                >
                                    {/* Filter out the selected 'to' option */}
                                    {to !== 'bank' && <MenuItem value="bank">Bank</MenuItem>}
                                    {to !== 'credit' && <MenuItem value="credit">Credit</MenuItem>}
                                    {to !== 'capital' && <MenuItem value="capital">Capital</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="to-label">Transfer To</InputLabel>
                                <Select
                                    labelId="to-label"
                                    id="transfer-to"
                                    value={to}
                                    onChange={(e) => setTo(e.target.value)}
                                    label="Transfer To"
                                    sx={{ mb: 1 }}
                                >
                                    {/* Filter out the selected 'from' option */}
                                    {from !== 'bank' && <MenuItem value="bank">Bank</MenuItem>}
                                    {from !== 'credit' && <MenuItem value="credit">Credit</MenuItem>}
                                    {from !== 'capital' && <MenuItem value="capital">Capital</MenuItem>}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            {/* Supplier Dropdown */}
                            <FormControl variant="outlined" fullWidth size="small">
                                <InputLabel id="supplier-label">Person</InputLabel>
                                <Select
                                    labelId="supplier-label"
                                    id="supplier-select"
                                    value={selectedSupplier}
                                    onChange={(e) => setSelectedSupplier(e.target.value)}
                                    label="Supplier"
                                    sx={{ mb: 1 }}
                                >
                                    {suppliers.map((supplier) => (
                                        <MenuItem key={supplier.id} value={supplier.supplier_name}>
                                            {supplier.supplier_name}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value="">
                                        <Link to="/addsupplier" style={{ textDecoration: 'none', color: 'inherit' }}>
                                            Add New
                                        </Link>
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                label="Amount"
                                value={amount}
                                onChange={(e) => setAmount(e.target.value)}
                                variant="outlined"
                                fullWidth
                                size="small"
                                sx={{ mb: 1 }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                type="date"
                                value={date || new Date().toISOString().split('T')[0]}
                                onChange={(e) => setDate(e.target.value)}
                                variant="outlined"
                                fullWidth
                                size="small"
                                sx={{ mb: 1 }}
                            />
                        </Grid>
                    </Grid>

                    {/* Right Side: Bank and Amount Display */}
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'center',
                            textAlign: 'right',
                            marginLeft: { xs: 0, md: '20px' },
                            marginTop: { xs: '20px', md: 0 },
                        }}
                    >
                        <Typography variant="subtitle1">BANK</Typography>
                        <Typography variant="h4" fontWeight="bold">
                            Lkr.{totalBankAmount.toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                        <Typography variant="subtitle1">AMOUNT</Typography>
                        <Typography variant="h4" fontWeight="bold">
                            Lkr. {Number(amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                        </Typography>
                    </Box>
                </Box>
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                    }}
                >
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#4287f5',
                            color: 'white',
                            marginTop: '30px',
                            borderRadius: '20px',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#356bc4',
                            },
                            marginBottom: { xs: '8px', sm: '0' }
                        }}
                        sx={{
                            '@media (max-width: 600px)': {
                              mr:0,
                            },
                        }}                    
                        >
                        Submit
                    </Button>
                </Box>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white', // Optional: to give a slightly white overlay
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default AddInvestment;

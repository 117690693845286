import React, { useState, useEffect } from "react";
import axios from "axios";
import {
    Table,
    TableBody,
    Button,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    CircularProgress,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Box,
    Typography
} from "@mui/material";
import { Delete as DeleteIcon, CheckCircle as CheckCircleIcon, Block as BlockIcon } from "@mui/icons-material";
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';

const UserEmailTable = () => {
    const [users, setUsers] = useState([]);
    const [userPayments, setUserPayments] = useState([]);
    const [loading, setLoading] = useState(true);
    const [open, setOpen] = useState(false);
    const [selectedPayment, setSelectedPayment] = useState(null);
    const [searchQuery, setSearchQuery] = useState(""); // New state for search query
    const [deleteUserId, setDeleteUserId] = useState(null);
    const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

    useEffect(() => {
        // Fetch users
        axios
            .get("https://admin.bizstor.lk/public/api/users")
            .then((response) => {
                const fetchedUsers = response.data;
                setUsers(fetchedUsers); // Set user data
                checkForExpiredUsers(fetchedUsers); // Check and deactivate expired users
                setLoading(false); // Stop loading
            })
            .catch((error) => {
                console.error("Error fetching users:", error);
                setLoading(false);
            });

        // Fetch user payments
        axios
            .get("https://admin.bizstor.lk/public/api/get-user-payment")
            .then((response) => {
                if (Array.isArray(response.data.userpayment)) {
                    setUserPayments(response.data.userpayment); // Set user payment data
                } else {
                    console.error("Expected an array for userPayments");
                }
            })
            .catch((error) => {
                console.error("Error fetching user payments:", error);
            });
    }, []);


    // Helper function to find the latest user payment by email
    const getUserPaymentByEmail = (email) => {
        if (!Array.isArray(userPayments)) {
            return null;
        }

        // Filter payments for the specific user by email
        const payments = userPayments.filter((payment) => payment.person === email);

        // Sort payments by the timestamp (created_at or updated_at) in descending order
        payments.sort((a, b) => new Date(b.created_at || b.updated_at) - new Date(a.created_at || a.updated_at));

        // Return the latest payment (first item after sorting)
        return payments[0];
    };

    // Open the dialog and set the selected payment details
    const handleSeeMore = (userPayment) => {
        setSelectedPayment(userPayment);
        setOpen(true);
    };

    // Close the dialog
    const handleClose = () => {
        setOpen(false);
        setSelectedPayment(null);
    };

    // Filter users by search query (email or name) and exclude the email 'biz@gmail.com'
    const filteredUsers = users.filter(
        (user) =>
            (user.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
                user.name?.toLowerCase().includes(searchQuery.toLowerCase())) &&
            user.email !== 'bizstoradmin@gmail.com' // Exclude 'bizstoradmin@gmail.com'
    );

    // Handle user deletion
    const handleDeleteConfirm = () => {
        if (deleteUserId) {
            axios
                .delete(`https://admin.bizstor.lk/public/api/users/${deleteUserId}`)
                .then((response) => {
                    setUsers(users.filter((user) => user.id !== deleteUserId)); // Filter out the deleted user
                    setOpenDeleteDialog(false);
                    alert(response.data.message);
                })
                .catch((error) => {
                    console.error("Error deleting user:", error);
                    setOpenDeleteDialog(false);
                    alert("Failed to delete user.");
                });
        }
    };

    // Deactivate user function
    const handleDeactivate = (id) => {
        axios
            .put(`https://admin.bizstor.lk/public/api/users/${id}/deactivate`)
            .then((response) => {
                setUsers(users.map((user) => (user.id === id ? { ...user, status: "deactivated" } : user))); // Update the status in UI
                alert(response.data.message);
            })
            .catch((error) => {
                console.error("Error deactivating user:", error);
                alert("Failed to deactivate user.");
            });
    };

    // Activate user function
    const handleActivate = (id) => {
        axios
            .put(`https://admin.bizstor.lk/public/api/users/${id}/activate`)
            .then((response) => {
                setUsers(users.map((user) => (user.id === id ? { ...user, status: "active" } : user))); // Update the status in UI
                alert(response.data.message);
            })
            .catch((error) => {
                console.error("Error activating user:", error);
                alert("Failed to activate user.");
            });
    };

    // Function to check for expired users and deactivate them
    const checkForExpiredUsers = (fetchedUsers) => {
        const currentDate = new Date();
        fetchedUsers.forEach((user) => {
            const expireDate = new Date(user.expiredate);
            if (expireDate < currentDate && user.status !== "deactivated") {
                // If expire date is passed and the user is not already deactivated
                handleDeactivate(user.id); // Automatically deactivate user
            }
        });
    };

    // Handle user delete
    const handleDelete = (id) => {
        setDeleteUserId(id);
        setOpenDeleteDialog(true);
    };

    // Close delete dialog
    const handleCloseDeleteDialog = () => {
        setOpenDeleteDialog(false);
        setDeleteUserId(null);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '95%',
                maxWidth: 'auto',
                margin: 'auto',
                mb: 3,
                boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                padding: '10px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative',
            }}
        >
            <Typography variant="h4">
                User Management
            </Typography>
            {/* Search bar */}
            <TextField
                variant="outlined"
                placeholder="Search…"
                size="small"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)} // Update search query
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <SearchIcon />
                        </InputAdornment>
                    ),
                }}
                sx={{ width: '300px', mt: 2, mb: 2 }}
            />

            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>User</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>User Email</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Register Date</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Status</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Package</TableCell>
                            <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user) => {
                            const userPayment = getUserPaymentByEmail(user.email);
                            return (
                                <TableRow key={user.id}>
                                    <TableCell>{user.id}</TableCell>
                                    <TableCell>{user.name}</TableCell>
                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{new Date(user.created_at).toLocaleDateString()}</TableCell>
                                    <TableCell
                                        sx={{
                                            color: user.status === 'active' ? 'green' : 'red',
                                            fontWeight: 'bold',
                                        }}
                                    >
                                        {user.status}
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleSeeMore(userPayment)}
                                        >
                                            See Package
                                        </Button>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={() => handleActivate(user.id)}
                                            startIcon={<CheckCircleIcon />}
                                            sx={{ ml: 1 }}
                                        >
                                            Activate
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="error"
                                            onClick={() => handleDeactivate(user.id)}
                                            startIcon={<BlockIcon />}
                                            sx={{ ml: 1 }}
                                        >
                                            Deactivate
                                        </Button>

                                        <Button
                                            variant="contained"
                                            color="secondary"
                                            onClick={() => handleDelete(user.id)}
                                            startIcon={<DeleteIcon />}
                                            sx={{ ml: 1 }}
                                        >
                                            Delete
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>Package Details</DialogTitle>
                <DialogContent>
                    {selectedPayment ? (
                        <Table>
                            <TableBody>
                                <TableRow>
                                    <TableCell>Email</TableCell>
                                    <TableCell>{selectedPayment.person}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Package</TableCell>
                                    <TableCell>{selectedPayment.package}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Amount</TableCell>
                                    <TableCell>Lkr.{Number(selectedPayment.amount).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Date</TableCell>
                                    <TableCell>{selectedPayment.date}</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell>Expire Date</TableCell>
                                    <TableCell>{selectedPayment.expiredate}</TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    ) : (
                        <p>No Package Data Available</p>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Close</Button>
                </DialogActions>
            </Dialog>

            {/* Delete user confirmation dialog */}
            <Dialog open={openDeleteDialog} onClose={handleCloseDeleteDialog}>
                <DialogTitle>Delete User</DialogTitle>
                <DialogContent>
                    <p>Are you sure you want to delete this user?</p>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDeleteDialog} color="primary">Cancel</Button>
                    <Button onClick={handleDeleteConfirm} color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white', // Optional: to give a slightly white overlay
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}

        </Box>
    );
};

export default UserEmailTable;

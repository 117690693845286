import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Grid,
} from '@mui/material';
import ScrollToTopButton from '../Button/ScrollToTopButton';
import { styled, keyframes } from '@mui/system';
import HomeIcon from '@mui/icons-material/Home';


const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const BalanceSheet = () => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [investments, setInvestments] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchInvestments();
  }, []);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return;
      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };
    fetchProfile();
  }, [token]);

  useEffect(() => {
    if (user.id) {
      fetchInvestments();
    }
  }, [user.id]);

  const fetchInvestments = async () => {
    try {
      const response = await axios.get('https://admin.bizstor.lk/public/api/get-investment');
      if (user.id) {
        const filteredInvestments = response.data.investment.filter(
          (investment) => investment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
        );
        setInvestments(filteredInvestments);
      } else {
        setInvestments([]); // In case user.id is not found
      }
    } catch (error) {
      console.error('Error fetching investment data:', error);
    } finally {
      setLoading(false); // Ensure loading is set to false after fetching
    }
  };

  // Group investments by person for `from === 'bank'`
  const fromBankByPerson = investments
    .filter((investment) => investment.from === 'bank')
    .reduce((acc, investment) => {
      const person = investment.person || 'Unknown';
      acc[person] = (acc[person] || 0) + Number(investment.amount);
      return acc;
    }, {});

  // Group investments by person for `to === 'bank'`
  const toBankByPerson = investments
    .filter((investment) => investment.to === 'bank')
    .reduce((acc, investment) => {
      const person = investment.person || 'Unknown';
      acc[person] = (acc[person] || 0) + Number(investment.amount);
      return acc;
    }, {});

  // Calculate total difference for each person
  const differenceByPerson = Object.keys(fromBankByPerson).map((person) => {
    const fromBankAmount = fromBankByPerson[person] || 0;
    const toBankAmount = toBankByPerson[person] || 0;
    return {
      person,
      balance: Math.abs((fromBankAmount - toBankAmount)).toFixed(2), // Use Math.abs() to get absolute value
    };
  });


  // Total sum for `from === 'bank'` and `to === 'bank'`
  const totalFromBank = investments
    .filter((investment) => investment.from === 'bank')
    .reduce((sum, investment) => sum + (Number(investment.amount) || 0), 0);

  const totalToBank = investments
    .filter((investment) => investment.to === 'bank')
    .reduce((sum, investment) => sum + (Number(investment.amount) || 0), 0);

  return (
    <Box>

      <AnimatedTypography
        component="h1"
        sx={{
          ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
          mt: { xs: 2, sm: 3 },
          color: '',
          letterSpacing: '0.01em',
          fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Typography
            component="span"
            sx={{
              display: { xs: 'none', sm: 'inline' },
              fontSize: { xs: '14px', sm: '16px' },
              fontWeight: 'bold',
              color: '',
            }}
          >
            Balance Sheet
          </Typography>
          {/* Hide separator, icon, and text on small screens */}
          <Typography
            component="span"
            sx={{
              display: { xs: 'none', sm: 'inline' }, // Hidden on xs
              ml: 1,
              fontSize: '14px',
              color: '',
            }}
          >
            |
          </Typography>
          <HomeIcon
            sx={{
              ml: 1,
              fontSize: '18px',
              color: '#6d7081',
            }}
          />
          <Typography
            component="span"
            sx={{
              ml: 0.5,
              fontSize: '14px',
              color: '#6d7081',
            }}
          >
            - Report - Balance Sheet
          </Typography>
        </Box>
      </AnimatedTypography>

      {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',
                    color: 'secondary.main',
                    letterSpacing: '0.01em'
                }}
            >
                Dashboard / Report / Balance Sheet
            </AnimatedTypography>*/}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '95%',
          maxWidth: 'auto',
          margin: 'auto',
          boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
          padding: '10px',
          borderRadius: '5px',
          bgcolor: 'background.paper',
          marginTop: '30px',
          position: 'relative',
          '@media (max-width: 600px)': {
            marginTop: '10px',
          },
        }}
      >
        <Typography variant="h4" gutterBottom>

        </Typography>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center', // Align items vertically
            '@media (max-width: 600px)': {
              margin: 'auto',
            },
            color: '#6d7081',
            ml: 2
          }}
        >
          <h2>BALANCE SHEET</h2>
        </Box>

        <Grid container spacing={2} sx={{ mt: 2 }}>
          {/* Table for 'to === bank' data */}
          <Grid item xs={12}>
            <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
              <Typography variant="h6" gutterBottom>
                Assets
              </Typography>
              <Table sx={{ width: '100%' }}> {/* Ensure full width of the table */}
                <TableHead>
                  <TableRow>
                    <TableCell><b>Assets</b></TableCell>
                    <TableCell align="right"><b>TOTAL</b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    <TableRow>
                      <TableCell sx={{ color: '#636363', fontSize: '14px' }}>Bank</TableCell>
                      <TableCell align="right" sx={{ color: '#636363', fontSize: '14px' }}>
                        Lkr. {(totalToBank - totalFromBank).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </TableCell>
                    </TableRow>
                  )}
                  <TableRow>
                    <TableCell sx={{ fontSize: '14px' }}><b>Total Assets</b></TableCell>
                    <TableCell align="right" sx={{ fontSize: '14px' }}>
                      <strong>
                        Lkr. {(totalToBank - totalFromBank).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>

          <Grid item xs={12}>
            <TableContainer sx={{ marginTop: 2, margin: 'auto', maxWidth: '95%' }}>
              <Typography variant="h6" gutterBottom>
                Liabilities and shareholder's equity
              </Typography>
              <Table sx={{ width: '100%' }}> {/* Ensure full width of the table */}
                <TableHead>
                  <TableRow>
                    <TableCell><b>Current Liabilities</b></TableCell>
                    <TableCell align="right"><b></b></TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {loading ? (
                    <TableRow>
                      <TableCell colSpan={2} align="center">
                        Loading...
                      </TableCell>
                    </TableRow>
                  ) : (
                    differenceByPerson.map(({ person, balance }) => (
                      <TableRow key={person}>
                        <TableCell sx={{ color: '#636363', fontSize: '14px' }}>{person}</TableCell>
                        <TableCell align="right" sx={{ color: '#636363', fontSize: '14px' }}>Lkr. {balance.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}</TableCell>
                      </TableRow>
                    ))
                  )}
                  <TableRow>
                    <TableCell sx={{ fontSize: '14px' }}><b>Total Liabilities</b></TableCell>
                    <TableCell align="right" sx={{ fontSize: '14px' }}>
                      <strong>
                        Lkr. {differenceByPerson.reduce((total, { balance }) => total + parseFloat(balance), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ',')}                      </strong>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
        </Grid>
        <ScrollToTopButton />
      </Box>
    </Box>
  );
};

export default BalanceSheet;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Box,
    Button,
    Dialog,
    AppBar,
    Toolbar,
    IconButton,
    Typography,
    Slide,
    TextField,
    Divider,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Snackbar,
    Alert,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PaymentsIcon from '@mui/icons-material/Payments';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { quantum, trio } from 'ldrs';

quantum.register();
trio.register();

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const MakePayment = ({ order }) => {
    const [open, setOpen] = useState(false);
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [paymentAmount, setPaymentAmount] = useState('');
    const [paymentId, setPaymentId] = useState('');
    const [paymentDate, setPaymentDate] = useState('');
    const [payments, setPayments] = useState([]);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [user, setUser] = useState({});
    const [editOpen, setEditOpen] = useState(false);
    const [editPayment, setEditPayment] = useState({});
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return;

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchPayments();
        }
    }, [user.id]);

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    const handleEditOpen = (payment) => {
        setEditPayment(payment);
        setEditOpen(true);
    };

    const handleEditClose = () => {
        setEditOpen(false);
    };

    const handleEditSave = async () => {
        setLoading(true); // Start loading

        try {
            const paymentData = {
                payment_id: editPayment.payment_id,
                amount: editPayment.amount,
                date: editPayment.date,
            };

            await axios.put(`https://admin.bizstor.lk/public/api/update-payment/${editPayment.id}`, paymentData);
            {/**console.log('Payment updated successfully:');*/}

            // Fetch updated payments
            fetchPayments();
            setAlertOpen(true);

            // Close the edit dialog after saving
            handleEditClose();
        } catch (error) {
            console.error('Error updating payment:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handleEditAmountChange = (event) => {
        setEditPayment({ ...editPayment, amount: event.target.value });
    };

    const handleEditDateChange = (event) => {
        setEditPayment({ ...editPayment, date: event.target.value });
    };


    useEffect(() => {
        if (order) {
            setPaymentId(order.id.toString());
            fetchPayments();
        }
    }, [order]);

    if (!order) return null;

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handlePay = async () => {
        setLoading(true); // Start loading

        try {
            const paymentData = {
                usertoken: user.id,
                payment_id: paymentId,
                amount: paymentAmount,
                date: paymentDate || new Date().toISOString().split('T')[0],
            };

            await axios.post('https://admin.bizstor.lk/public/api/save-payment-data', paymentData);

            // Clear payment amount after payment
            setPaymentAmount('');
            setPaymentDate('');
            handleSnackbar('Payment successfully!', 'success');
            // Fetch updated payments
            fetchPayments();

            // Close the dialog after payment
        } catch (error) {
            console.error('Error processing payment:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const handlePayAndClose = async () => {
        setLoading(true); // Start loading

        try {
            const paymentData = {
                usertoken: user.id,
                payment_id: paymentId,
                amount: paymentAmount,
                date: paymentDate || new Date().toISOString().split('T')[0],
            };

            await axios.post('https://admin.bizstor.lk/public/api/save-payment-data', paymentData);

            // Clear payment amount after payment
            setPaymentAmount('');
            setPaymentDate('');

            // Fetch updated payments
            fetchPayments();
            setAlertOpen(true);

            // Close the dialog after payment
            handleClose();
        } catch (error) {
            console.error('Error processing payment:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const fetchPayments = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-payment-data')
            if (user.id) {
                const Payment = response.data.filter(payment =>
                    payment.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setPayments(Payment);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoading(false);
        }

    };

    const deletePayment = async (paymentId) => {
        setLoading(true); // Start loading

        try {
            await axios.delete(`https://admin.bizstor.lk/public/api/delete-payment-data/${paymentId}`);
            {/**console.log('Payment deleted successfully:');*/}
            handleSnackbar('Payment deleted successfully!', 'success');
            // Fetch updated payments
            fetchPayments();
            setAlertOpen(true);
        } catch (error) {
            console.error('Error deleting payment:', error);
        } finally {
            setLoading(false); // Stop loading
        }
    };

    const calculateTotalAmount = (items, discount) => {
        // Calculate the total amount considering item-specific taxes
        const totalAmount = items.reduce((acc, item) => {
            const itemTotal = item.price + (item.price * (item.tax / 100)); // Adding item-specific tax
            return acc + itemTotal;
        }, 0);

        // Apply discount
        const discountedAmount = totalAmount - (totalAmount * (discount / 100));

        // Calculate total amount
        return discountedAmount;
    };

    const getPriceForProduct = (productName) => {
        return productPrices[productName] || 0;
    };

    const productPrices = {
        "DOT4 250ml (White)": 850,
        "DOT3 250ml (Red)": 580,
        "DOT3 250ml (White)": 580,
        "DOT3 100ml (Red)": 290,
        "DOT3 100ml (White)": 290,
        "DOT3 50ml (Red)": 170,
        "DOT3 50ml (White)": 170,
        "Green 1L": 750,
        "4L + 4 Water(L)": 1800,
        "4L Pure": 1350
    };

    const handleAmountChange = (event) => {
        setPaymentAmount(event.target.value);
    };

    const handleDateChange = (event) => {
        setPaymentDate(event.target.value);
    };

    const dueAmount = calculateTotalAmount(order.items, order.discount) - order.payamount;

    const calculateTotalPayments = () => {
        let total = 0;
        payments.forEach((payment) => {
            if (order.id === payment.payment_id) {
                total += payment.amount;
            }
        });
        return total;
    };

    const getPaymentStatus = () => {
        const totalPaid = order.payamount + calculateTotalPayments();
        const totalAmount = calculateTotalAmount(order.items, order.discount);

        if (totalPaid === totalAmount) {
            return 'Paid'
        }
        if (totalPaid > totalAmount) {
            return 'Overpaid'
        } else {
            return 'Pending'
        }
    };

    const paymentStatus = getPaymentStatus();

    return (
        <React.Fragment>
            <Box>
                <Box display="flex" justifyContent="space-between" alignItems="center" mt={2}>
                    <Button variant="outlined" onClick={handleClickOpen} style={{ backgroundColor: 'black', color: 'white' }}>
                        Make Payment
                        <PaymentsIcon sx={{ marginLeft: '10px' }} />
                    </Button>
                </Box>
                <Dialog
                    fullScreen
                    open={open}
                    onClose={handleClose}
                    TransitionComponent={Transition}
                >
                    <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                        <Toolbar>
                            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                                <CloseIcon sx={{ color: 'white' }} />
                            </IconButton>
                            <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                                Make Payment
                            </Typography>
                        </Toolbar>
                    </AppBar>
                    <Box sx={{ p: 3 }}>
                        <Box>
                            <Typography
                            sx={{ 
                                mb: 2,
                                fontSize: '30px',
                                fontWeight:'bold',
                                '@media (max-width: 600px)': {
                                    fontSize: '22px',
                                    fontWeight:'bold',
                                    mb:4,
                                },
                             }}>Payment for Order : {order.invoiceNo} - {paymentStatus} </Typography>
                            {/**<p><strong>Full Name :</strong> {order.fullname}</p>*/}
                            {/**<p><strong>Customer :</strong> {order.customer}</p>*/}
                            <p><strong>Total Amount :</strong> Rs.{calculateTotalAmount(order.items, order.discount).toFixed(2)}</p>
                            <p>
                                <strong>Pay On Receipt :</strong> Rs.{(order.payamount != null ? order.payamount.toFixed(2) : '0.00')}
                            </p>
                            <p><strong>Other Payments :</strong> Rs.{calculateTotalPayments().toFixed(2)}</p>
                            <p><strong>Total Payments :</strong> Rs.{((order.payamount != null ? order.payamount : 0) + calculateTotalPayments()).toFixed(2)}</p>                            <Divider sx={{ my: 2 }} />
                            <Box
                                sx={{
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'flex-end',
                                    justifyContent: 'flex-end',
                                    marginTop: '10px',
                                    textAlign: 'right',
                                }}
                            >
                                <Typography variant="subtitle1">Due Amount</Typography>
                                <Typography variant="h4" fontWeight="bold" sx={{ mb: 2 }}>
                                    Lkr.{dueAmount - calculateTotalPayments().toFixed(2)}
                                </Typography>
                            </Box>
                        </Box>


                        <Box sx={{ mt: 3 }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>Make Payment</Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            {/**<TableCell>ID</TableCell>*/}
                                            <TableCell align="left">Pay Amount</TableCell>
                                            <TableCell align="left">Pay Date</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        <TableRow>
                                            {/**<TableCell component="th" scope="row">
                                                <TextField
                                                    value={paymentId}
                                                    disabled
                                                    size="small"
                                                    sx={{ width: '100%' }}
                                                />
                                            </TableCell>*/}
                                            <TableCell align="left">
                                                <TextField
                                                    type="number"
                                                    value={paymentAmount}
                                                    size="small"
                                                    onChange={handleAmountChange}
                                                    sx={{ 
                                                        width: '100%',
                                                    '@media (max-width: 600px)': {
                                                        width: '90%',
                                                        },
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell align="left">
                                                <TextField
                                                    type="date"
                                                    value={paymentDate || new Date().toISOString().split('T')[0]}
                                                    size="small"
                                                    onChange={handleDateChange}
                                                    sx={{ 
                                                        width: '100%',
                                                    '@media (max-width: 600px)': {
                                                        width: '90%',
                                                        },
                                                    }}
                                                />
                                            </TableCell>
                                        </TableRow>
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box display="flex" justifyContent="flex-end" mt={2}>
                                <Button onClick={handlePay} variant="contained" color="primary" style={{ backgroundColor: 'black', color: 'white', marginRight: '10px' }}>
                                    {loading ? (
                                        <Box sx={{
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            width: '100vw',
                                            height: '100vh',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            bgcolor: 'white', // Optional: to give a slightly white overlay
                                            zIndex: 9999
                                        }}>
                                            <l-trio
                                                size="50"
                                                speed="1.3"
                                                color="red"
                                            ></l-trio>
                                        </Box>
                                    ) : 'Pay'}
                                </Button>
                                <Button onClick={handlePayAndClose} variant="contained" color="error">
                                    {loading ? (
                                        <Box sx={{
                                            position: 'fixed',
                                            top: 0,
                                            left: 0,
                                            width: '100vw',
                                            height: '100vh',
                                            display: 'flex',
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            bgcolor: 'white', // Optional: to give a slightly white overlay
                                            zIndex: 9999
                                        }}>
                                            <l-trio
                                                size="50"
                                                speed="1.3"
                                                color="red"
                                            ></l-trio>
                                        </Box>
                                    ) : 'Pay & Close'}
                                </Button>
                            </Box>
                        </Box>


                        <Box sx={{ mt: 3 }}>
                            <Typography variant="h6" sx={{ mb: 2 }}>Payment History</Typography>
                            <TableContainer component={Paper}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left">Pay Amount</TableCell>
                                            <TableCell align="right">Pay Date</TableCell>
                                            <TableCell align="right">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {payments.map((payment, index) => {
                                            if (order.id === payment.payment_id) {
                                                return (
                                                    <TableRow key={payment.id}>
                                                        <TableCell align="left">Rs.{payment.amount.toFixed(2)}</TableCell>
                                                        <TableCell align="right">{payment.date}</TableCell>
                                                        <TableCell align="right">
                                                            <IconButton onClick={() => handleEditOpen(payment)}>
                                                                <EditIcon
                                                                    sx={{
                                                                        '&:hover': {
                                                                            color: 'blue',
                                                                        },
                                                                    }} />
                                                            </IconButton>
                                                            <IconButton aria-label="delete" onClick={() => deletePayment(payment.id)}>
                                                                {loading ? (
                                                                    <Box sx={{
                                                                        position: 'fixed',
                                                                        top: 0,
                                                                        left: 0,
                                                                        width: '100vw',
                                                                        height: '100vh',
                                                                        display: 'flex',
                                                                        alignItems: 'center',
                                                                        justifyContent: 'center',
                                                                        bgcolor: 'white', // Optional: to give a slightly white overlay
                                                                        zIndex: 9999
                                                                    }}>
                                                                        <l-trio
                                                                            size="50"
                                                                            speed="1.3"
                                                                            color="red"
                                                                        ></l-trio>
                                                                    </Box>
                                                                ) : (<DeleteIcon
                                                                    sx={{
                                                                        '&:hover': {
                                                                            color: 'red',
                                                                        },
                                                                    }} />)}
                                                            </IconButton>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            } else {
                                                return null; // Render nothing if condition is not met
                                            }
                                        })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            
                            <Snackbar
                                open={snackbarOpen}
                                autoHideDuration={6000}
                                onClose={handleSnackbarClose}
                                anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                            >
                                <Alert
                                    onClose={handleSnackbarClose}
                                    severity={snackbarSeverity}
                                    sx={{
                                        width: '100%',

                                    }}
                                >
                                    {snackbarMessage}
                                </Alert>
                            </Snackbar>
                        </Box>
                    </Box>
                </Dialog>
                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white', // Optional: to give a slightly white overlay
                        zIndex: 9999
                    }}>
                        <l-trio
                                        size="50"
                                        speed="1.3"
                                        color="red"
                        ></l-trio>
                    </Box>
                )}

                <Snackbar
                    open={alertOpen}
                    autoHideDuration={6000}
                    onClose={() => setAlertOpen(false)}
                    message="Saved successfully!"
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                />
            </Box>

            <Dialog
                open={editOpen}
                onClose={handleEditClose}
                TransitionComponent={Transition}
                maxWidth="md" // or "lg" for larger width
                fullWidth
            >
                <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleEditClose} aria-label="close">
                            <CloseIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Edit Payment
                        </Typography>
                    </Toolbar>
                </AppBar>
                <Box sx={{ p: 3 }}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'row', // Set to row to arrange inputs horizontally
                            alignItems: 'center', // Align items vertically centered
                            justifyContent: 'space-between', // Space between inputs
                            marginTop: '10px',
                            textAlign: 'right'
                        }}
                    >
                        <TextField
                            label="Payment Amount"
                            type="number"
                            value={editPayment.amount}
                            size="small"
                            onChange={handleEditAmountChange}
                            sx={{ marginBottom: '10px', width: '48%' }} // Adjust width to fit two inputs in a row
                        />
                        <TextField
                            label="Payment Date"
                            type="date"
                            value={editPayment.date}
                            size="small"
                            onChange={handleEditDateChange}
                            InputLabelProps={{
                                shrink: true,
                            }}
                            sx={{ marginBottom: '10px', width: '48%' }} // Adjust width to fit two inputs in a row
                        />
                    </Box>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'flex-end',
                            justifyContent: 'flex-end',
                            marginTop: '10px',
                            textAlign: 'right'
                        }}
                    >
                        <Button
                            variant="contained"
                            onClick={handleEditSave}
                            sx={{
                                backgroundColor: 'black',
                                color: 'white',
                                '&:hover': {
                                    backgroundColor: '#343a40',
                                },
                                width: '200px',
                            }}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </Dialog>
        </React.Fragment>
    );
};

export default MakePayment;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TableContainer,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
    Paper,
    IconButton,
    Box,
    Typography,
    Avatar,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Snackbar,
    Alert,
    TablePagination,
    Slide,
    AppBar,
    Toolbar
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import ScrollToTopButton from '../../Button/ScrollToTopButton';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ProductTable = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openConfirmation, setOpenConfirmation] = useState(false);
    const [selectedProductId, setSelectedProductId] = useState(null);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
    const [currentProduct, setCurrentProduct] = useState({});
    const [image, setImage] = useState(null);
    const [filteredData, setFilteredData] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
    const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    
      const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setSnackbarOpen(false);
      };
    

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);
    
    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token
    
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };
    
        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchProducts();
        }
    }, [user.id]);


    useEffect(() => {
        fetchProducts();
    }, []);

    const fetchProducts = () => {
        setLoading(true);
        axios.get('https://admin.bizstor.lk/public/api/get-product-data')
            .then(response => {
                if (user.id) {
                    const nisalOrders = response.data.products.filter(product => 
                        product.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setProducts(nisalOrders);
                    setFilteredData(nisalOrders);
                }
                // Assuming the API response structure contains an array of products
                 // Initialize with all products
                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false);
            });
    };

    const handleDelete = (productId) => {
        setSelectedProductId(productId);
        setOpenConfirmation(true);
    };

    const confirmDelete = () => {
        setLoading(true);
        axios.delete(`https://admin.bizstor.lk/public/api/delete-product-data/${selectedProductId}`)
            .then(response => {
                setProducts(products.filter(product => product.id !== selectedProductId));
                setLoading(false);
                setOpenConfirmation(false);
                handleSnackbar('Product deleted successfully!', 'success');
                setOpenAlert(true);
            })
            .catch(error => {
                console.error('Error deleting product:', error);
                setLoading(false);
                setOpenConfirmation(false);
                handleSnackbar('Failed to delete product!', 'error');
                setOpenAlert(true);
            });
    };

    const handleCloseAlert = () => {
        setOpenAlert(false);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleUpdateClick = (product) => {
        setCurrentProduct(product);
        setOpenUpdateDialog(true);
    };

    const handleUpdateChange = (e) => {
        setCurrentProduct({
            ...currentProduct,
            [e.target.name]: e.target.value
        });
    };

    const handleImageChange = (e) => {
        setImage(e.target.files[0]);
    };

    const handleUpdateSubmit = () => {
        setLoading(true);
    
        const formData = new FormData();
        formData.append('product', currentProduct.product);
        formData.append('price', currentProduct.price);
        formData.append('Bprice', currentProduct.Bprice);
        formData.append('code', currentProduct.code);
        if (image) {
            formData.append('image', image);
        }
    
        axios.post(`https://admin.bizstor.lk/public/api/update-product-data/${currentProduct.id}`, formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        })
        .then(response => {
            setProducts(products.map(product => 
                product.id === currentProduct.id ? response.data.product : product
            ));
            setLoading(false);
            setOpenUpdateDialog(false);
            handleSnackbar('Product updated successfully!', 'success');
            setOpenAlert(true);
        })
        .catch(error => {
            console.error('Error updating product:', error);
            setLoading(false);
            handleSnackbar('Failed to update product!', 'error');
            setOpenAlert(true);
        });
    };

    const handleSearchChange = (event) => {
        const value = event.target.value.toLowerCase();
        setSearchTerm(value);
        filterData(value);
    };
    
    // Function to filter product data based on search term
    const filterData = (term) => {
        const filtered = products.filter(product => {
          return (
            product?.id?.toString().includes(term) ||
            product?.code?.toString().includes(term) ||
            product?.product?.toLowerCase().includes(term)
          );
        });
        setFilteredData(filtered);
      };
      
    
    

    return (
        <Box
            sx={{
                display: 'flex',
          flexDirection: 'column',
          width: '95%',
          maxWidth: 'auto',
          margin: 'auto',
          boxShadow: 1,
          padding: '10px',
          borderRadius: '5px',
          bgcolor: 'background.paper',
          marginTop: '30px',
          position: 'relative'
            }}
        >
            <Typography variant="h5">

            </Typography>

            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between', // Space between left and right groups
                    alignItems: 'center', // Align items vertically
                    p: 2, // Padding inside the box
                }}
            >
                {/* Left side: Search fields */}
                <Box
                    sx={{
                        display: 'flex',
                        gap: 2, // Space between the TextFields
                    }}
                >
                    <TextField
                        variant="outlined"
                        margin="normal"
                        onChange={handleSearchChange}
                        value={searchTerm}
                        size="small"
                        placeholder="Search…"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SearchIcon />
                                </InputAdornment>
                            ),
                        }}
                        sx={{ width: '250px' }}
                    />
                
                </Box>
            </Box>

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                   <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                </Box>
            )}

            {!loading && (
                <>
                    <TableContainer component={Paper} sx={{ marginTop: '20px' }}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                {/**    <TableCell sx={{ fontWeight: 'bold' }}>ID</TableCell>*/} 
                                    <TableCell sx={{ fontWeight: 'bold' }}>Product Image</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Product Code</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Product Name</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Buying Price</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Selling Price</TableCell>
                                    <TableCell sx={{ fontWeight: 'bold' }}>Action</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(product => (
                                    <TableRow key={product.id}>
                                     {/**   <TableCell>{product.id}</TableCell>*/} 
                                        <TableCell>
                                            {product.image ? (
                                                <Avatar
                                                    src={`https://admin.bizstor.lk/storage/app/public/${product.image}`}
                                                    alt={product.product}
                                                    sx={{ width: 60, height: 60, borderRadius: '50%' }}
                                                />
                                            ) : (
                                                <Avatar sx={{ width: 60, height: 60, borderRadius: '50%', bgcolor: 'grey' }} />
                                            )}
                                        </TableCell>
                                        <TableCell>{product.code}</TableCell>
                                        <TableCell>{product.product}</TableCell>
                                        <TableCell>Rs.{product.Bprice}</TableCell>
                                        <TableCell>Rs.{product.price}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleUpdateClick(product)} aria-label="edit"
                                                sx={{
                                                    '&:hover': {
                                                        color: 'blue',
                                                    },
                                                }}>
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(product.id)} aria-label="delete"
                                                sx={{
                                                    '&:hover': {
                                                        color: 'red',
                                                    },
                                                }}>
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    <TablePagination
                        rowsPerPageOptions={[5, 10, 15]}
                        component="div"
                        count={products.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </>
            )}

            <Dialog
                open={openUpdateDialog}
                onClose={() => setOpenUpdateDialog(false)}
                TransitionComponent={Transition}
                aria-labelledby="update-dialog-title"
                fullWidth
            >
                
                <AppBar sx={{ position: 'relative', bgcolor: 'black' }}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={() => setOpenUpdateDialog(false)} aria-label="close">
                            <CloseIcon sx={{ color: 'white' }} />
                        </IconButton>
                        <Typography sx={{ ml: 2, flex: 1, color: 'white' }} variant="h6" component="div">
                            Edit Product
                        </Typography>
                    </Toolbar>
                </AppBar>
                <DialogContent>
                    <TextField
                        autoFocus
                        margin="dense"
                        name="product"
                        label="Product Name"
                        type="text"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={currentProduct.product}
                        onChange={handleUpdateChange}
                    />
                    <TextField
                        margin="dense"
                        name="Bprice"
                        label="Buying Price"
                        type="number"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={currentProduct.Bprice}
                        onChange={handleUpdateChange}
                        sx={{
                            mt:2
                        }}
                    />
                    <TextField
                        margin="dense"
                        name="price"
                        label="Selling Price"
                        type="number"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={currentProduct.price}
                        onChange={handleUpdateChange}
                        sx={{
                            mt:2
                        }}
                    />
                    <TextField
                        margin="dense"
                        name="code"
                        label="Code"
                        type="number"
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={currentProduct.code}
                        onChange={handleUpdateChange}
                        sx={{
                            mt:2
                        }}
                    />
                    <input
                        accept="image/*"
                        type="file"
                        onChange={handleImageChange}
                        style={{ marginTop: '20px' }}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenUpdateDialog(false)} color="primary">Cancel</Button>
                    <Button onClick={handleUpdateSubmit} color="primary">Update</Button>
                </DialogActions>
            </Dialog>

            <Dialog
                open={openConfirmation}
                onClose={() => setOpenConfirmation(false)}
                aria-labelledby="delete-dialog-title"
                fullWidth
            >
                <DialogTitle id="delete-dialog-title">Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography variant="body1">Are you sure you want to delete this product?</Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setOpenConfirmation(false)} color="primary">Cancel</Button>
                    <Button onClick={confirmDelete} color="secondary">Delete</Button>
                </DialogActions>
            </Dialog>

            <Snackbar
          open={snackbarOpen}
          autoHideDuration={6000}
          onClose={handleSnackbarClose}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}  
        >
          <Alert
            onClose={handleSnackbarClose}
            severity={snackbarSeverity}
            sx={{
              width: '100%',
              
            }}
          >
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <ScrollToTopButton/>
        </Box>
    );
};

export default ProductTable;

import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Typography,
  CircularProgress,
  useTheme,
} from "@mui/material";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  ResponsiveContainer,
} from "recharts";

const UserCreationChart = () => {
  const [chartData, setChartData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const theme = useTheme();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get("https://admin.bizstor.lk/public/api/users");
        const allUsers = response.data;

        const today = new Date();
        const daysInMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
        const data = Array.from({ length: daysInMonth }, (_, index) => ({
          day: index + 1,
          users: 0,
        }));

        allUsers.forEach((user) => {
          const createdAt = new Date(user.created_at);
          if (
            createdAt.getMonth() === today.getMonth() &&
            createdAt.getFullYear() === today.getFullYear()
          ) {
            const day = createdAt.getDate();
            data[day - 1].users += 1;
          }
        });

        setChartData(data);
        setLoading(false);
      } catch (err) {
        setError("Failed to fetch user data. Please try again later.");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading) {
    return (
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "400px",
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Typography
        sx={{ color: "error.main", textAlign: "center", mt: 4 }}
      >
        {error}
      </Typography>
    );
  }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        width: "95%",
        maxWidth: "1200px",
        margin: "auto",
        boxShadow: "0 4px 20px rgba(0, 0, 0, 0.1)",
        padding: "16px",
        borderRadius: "8px",
        bgcolor: "background.paper",
        marginTop: "30px",
        position: "relative",
        mb: 3,
        [theme.breakpoints.down("sm")]: {
          padding: "8px",
        },
      }}
    >
      <Typography
        variant="h6"
        sx={{
          textAlign: "center",
          mb: 2,
          fontWeight: "bold",
          fontSize: "1.2rem",
        }}
      >
        Users
      </Typography>
      <ResponsiveContainer width="100%" height={400}>
        <BarChart data={chartData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis
            dataKey="day"
            label={{ value: "", position: "insideBottom", offset: -5 }}
          />
          <YAxis
            label={{ value: "Users Created", angle: -90, position: "insideLeft" }}
          />
          <Tooltip />
          <Bar dataKey="users" fill="#8884d8" />
        </BarChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default UserCreationChart;

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import {
  LineChart, Line, BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, Cell, PieChart, Pie,
} from 'recharts';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField
} from '@mui/material';

const Chart = ({ onTotalAmountChange }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [fetchedData, setFetchedData] = useState([]);
  const [fetchedExpenses, setFetchedExpenses] = useState([]);
  const [fetchedDamage, setFetchedDamage] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dailyData, setDailyData] = useState([]);
  const [chartType, setChartType] = useState('line');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1); // Default to current month
  const [dayByDayData, setDayByDayData] = useState([]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return;

      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);

  useEffect(() => {
    const fetchData = async () => {
      if (user.id) {
        setLoading(true);
        try {
          const [dataResponse, expensesResponse, damageResponse] = await Promise.all([
            axios.get('https://admin.bizstor.lk/public/api/get-data'),
            axios.get('https://admin.bizstor.lk/public/api/get-expense-data'),
            axios.get('https://admin.bizstor.lk/public/api/get-damage-data')
          ]);

          const filteredOrders = dataResponse.data.filter(order =>
            order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );
          setFetchedData(filteredOrders);

          const filteredExpenses = expensesResponse.data.filter(expense =>
            expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
          );
          setFetchedExpenses(filteredExpenses);

          const filteredDamages = damageResponse.data.filter(damage =>
            damage.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
            damage.type === 'money back'
          );
          setFetchedDamage(filteredDamages);

        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      }
    };

    fetchData();
  }, [user.id]);

  useEffect(() => {
    if (!fetchedData.length && !fetchedExpenses.length && !fetchedDamage.length) return;

    const now = new Date();
    const currentMonth = now.getMonth() + 1;
    const start = new Date(now.getFullYear(), selectedMonth - 1, 1);
    const end = new Date(now.getFullYear(), selectedMonth, 0);

    const dailyTotals = Array.from({ length: 32 }, (_, i) => ({
      day: i + 1,
      orderAmount: 0,
      expenseAmount: 0,
      returnAmount: 0,
    }));

    fetchedData.forEach(order => {
      const createdAt = new Date(order.created_at);
      if (createdAt >= start && createdAt <= end) {
        const day = createdAt.getDate();
        order.items.forEach(item => {
          dailyTotals[day - 1].orderAmount += item.price;
        });
      }
    });

    fetchedExpenses.forEach(expense => {
      const createdAt = new Date(expense.created_at);
      if (createdAt >= start && createdAt <= end) {
        const day = createdAt.getDate();
        expense.items.forEach(item => {
          dailyTotals[day - 1].expenseAmount += item.amount;
        });
      }
    });

    fetchedDamage.forEach(damage => {
      const createdAt = new Date(damage.created_at);
      if (createdAt >= start && createdAt <= end) {
        const day = createdAt.getDate();
        damage.items.forEach(item => {
          dailyTotals[day - 1].returnAmount += item.price;
        });
      }
    });

    setDailyData(dailyTotals);

    if (onTotalAmountChange) {
      onTotalAmountChange(dailyTotals);
    }
  }, [fetchedData, fetchedExpenses, fetchedDamage, selectedMonth, onTotalAmountChange]);

  useEffect(() => {
    const now = new Date();
    const currentMonth = now.getMonth() + 1;
    const start = new Date(now.getFullYear(), currentMonth - 1, 1).toISOString().split('T')[0];
    const end = new Date(now.getFullYear(), currentMonth, 0).toISOString().split('T')[0];

    setStartDate(start);
    setEndDate(end);
  }, [selectedMonth]);

  const handleChartTypeChange = (event) => {
    setChartType(event.target.value);
  };

  const handleDateChange = (event) => {
    const { name, value } = event.target;
    if (name === 'startDate') setStartDate(value);
    if (name === 'endDate') setEndDate(value);
  };

  const handleMonthChange = (event) => {
    const selectedMonth = event.target.value;
    setSelectedMonth(selectedMonth);

    const start = new Date(new Date().getFullYear(), selectedMonth - 1, 1);
    const end = new Date(new Date().getFullYear(), selectedMonth, 0);

    setStartDate(start.toISOString().split('T')[0]);
    setEndDate(end.toISOString().split('T')[0]);
  };

  const totalReturnAmount = dailyData.reduce((sum, entry) => sum + entry.returnAmount, 0);
  const totalExpenseAmount = dailyData.reduce((sum, entry) => sum + entry.expenseAmount, 0);
  const totalOrderAmount = dailyData.reduce((sum, entry) => sum + entry.orderAmount, 0);

  const months = [
    { label: 'January', value: 1 },
    { label: 'February', value: 2 },
    { label: 'March', value: 3 },
    { label: 'April', value: 4 },
    { label: 'May', value: 5 },
    { label: 'June', value: 6 },
    { label: 'July', value: 7 },
    { label: 'August', value: 8 },
    { label: 'September', value: 9 },
    { label: 'October', value: 10 },
    { label: 'November', value: 11 },
    { label: 'December', value: 12 }
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '95%',
        maxWidth: 'auto',
        margin: 'auto',
        boxShadow: 1,
        padding: '10px',
        borderRadius: '5px',
        bgcolor: 'background.paper',
        marginTop: '30px',
        position: 'relative'
      }}
    >
      {loading ? (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'white',
          zIndex: 9999
        }}>
          <l-trio
            size="50"
            speed="1.3"
            color="red"
          ></l-trio>
        </Box>
      ) : (
        <Box>
          <Box>
            <Typography variant="h6" gutterBottom
              sx={{
                mb: 2
              }}
            >
              Monthly Sales, Returns and Expenses
            </Typography>

            <Box
              sx={{
                position: 'relative', // Changed to relative for better positioning in a responsive layout
                display: 'flex',
                flexWrap: 'wrap', // Ensures items wrap on smaller screens
                justifyContent: 'flex-end', // Aligns content to the right
                gap: '10px', // Adds space between items
                mt: '5px',
                mb: '10px',
                top: '5px',
              }}
            >
              <FormControl variant="outlined" sx={{ width: { xs: '100%', sm: '150px' } }}>
                <InputLabel>Month</InputLabel>
                <Select
                  value={selectedMonth}
                  onChange={handleMonthChange}
                  label="Month"
                  size="small"
                  sx={{ mr: { xs: 0, sm: 2 } }} // Adjusts margin based on screen size
                >
                  {months.map(month => (
                    <MenuItem key={month.value} value={month.value}>
                      {month.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>

              <TextField
                label="Start Date"
                type="date"
                name="startDate"
                size="small"
                value={startDate}
                onChange={handleDateChange}
                sx={{
                  width: { xs: '100%', sm: 100 },
                  mr: { xs: 0, sm: 2 }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <TextField
                label="End Date"
                type="date"
                name="endDate"
                size="small"
                value={endDate}
                onChange={handleDateChange}
                sx={{
                  width: { xs: '100%', sm: 100 },
                  mr: { xs: 0, sm: 2 }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
              />

              <FormControl     variant="outlined" sx={{ width: { xs: '100%', sm: '150px' } }}>
                <InputLabel id="chart-type-label">Select Chart Type</InputLabel>
                <Select
                labelId="chart-type-label"
                id="chart-type-select"
                  value={chartType}
                  onChange={handleChartTypeChange}
                  label="Select Chart Type"
                  size="small"
      sx={{ mr: { xs: 0, sm: 2 } }} 
                >
                  <MenuItem value="line">Line Chart</MenuItem>
                  <MenuItem value="bar">Bar Chart</MenuItem>
                  <MenuItem value="pie">Pie Chart</MenuItem>
                </Select>
              </FormControl>
            </Box>



          </Box>

          {chartType === 'line' && (
            <ResponsiveContainer width="100%" height={400}>
              <LineChart
                width={500}
                height={300}
                data={dailyData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5, }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Line type="monotone" dataKey="orderAmount" stroke="#8884d8" strokeWidth={2} name="Orders" />
                <Line type="monotone" dataKey="expenseAmount" stroke="red" strokeWidth={2} name="Expenses" />
                <Line type="monotone" dataKey="returnAmount" stroke="#FFB300" strokeWidth={2} name="Returns" />
              </LineChart>
            </ResponsiveContainer>
          )}

          {chartType === 'bar' && (
            <ResponsiveContainer width="100%" height={400}>
              <BarChart
                width={500}
                height={300}
                data={dailyData}
                margin={{ top: 30, right: 30, left: 20, bottom: 5 }}
              >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="day" />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="orderAmount" fill="#8884d8" name="Orders" />
                <Bar dataKey="expenseAmount" fill="red" name="Expenses" />
                <Bar dataKey="returnAmount" fill="#FFB300" name="Returns" />
              </BarChart>
            </ResponsiveContainer>
          )}

          {chartType === 'pie' && (
            <ResponsiveContainer width="100%" height={400}>
              <PieChart>
                <Pie
                  data={[
                    { name: 'Return Amount', value: totalReturnAmount },
                    { name: 'Expense Amount', value: totalExpenseAmount },
                    { name: 'Sell Amount', value: totalOrderAmount }
                  ]}
                  dataKey="value"
                  cx="50%"
                  cy="50%"
                  outerRadius={120}
                  fill="#8884d8"
                  label
                >
                  <Cell key="cell-return" fill="#FFB300" />
                  <Cell key="cell-expense" fill="red" />
                  <Cell key="cell-sell" fill="#8884d8" />
                </Pie>
                <Tooltip />
                <Legend />
              </PieChart>
            </ResponsiveContainer>
          )}

        </Box>
      )}
    </Box>
  );
};

export default Chart;

import React, { useState } from 'react';
import axios from 'axios';
import {
  TextField, Button, Typography, Container, Box, Snackbar,
  Alert,
} from '@mui/material';
import { Link } from 'react-router-dom';
import { styled, keyframes } from '@mui/system';

// Animation for sliding in from the left
const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

// Styled Typography with animation
const AnimatedTypography = styled(Typography)(({ theme }) => ({
  animation: `${slideInFromLeft} 0.5s ease-out`,
}));

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [key, setKey] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false); // State for Snackbar visibility
  const [snackbarMessage, setSnackbarMessage] = useState(''); // State for Snackbar message
  const [snackbarSeverity, setSnackbarSeverity] = useState('success'); // State for Snackbar severity
  const [loading, setLoading] = useState(false);

  const handleSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading before the request is made

    try {
      const response = await axios.post('https://admin.bizstor.lk/public/api/forgot-password', { email });
      handleSnackbar('Reset link sent successfully!', 'success'); // Show success message
    } catch (error) {
      if (error.response) {
        handleSnackbar('Failed to send reset link!', 'error'); // Show error message if response fails
      } else {
        handleSnackbar('An unexpected error occurred!', 'error'); // Handle unexpected errors
      }
    } finally {
      setLoading(false); // Stop loading in both success and error cases
    }
  };


  React.useEffect(() => {
    setKey(prevKey => prevKey + 1); // Update key to force re-render
  }, []);

  return (
    <Container maxWidth="xs">
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',  // Center vertically
          height: '100vh',  // Full viewport height
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            p: 4,
            boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
            borderRadius: 2,
            bgcolor: 'background.paper',
          }}
        >
          <AnimatedTypography key={key} component="h1" variant="h5">
            Forgot Password ?
          </AnimatedTypography>
          <Typography
            sx={{
              mt: 2
            }}
          >You can reset your password here.</Typography>
          <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              variant="standard"
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email"
              name="email"
              autoComplete="email"
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{
                mt: 3,
                mb: 2,
                borderRadius: 20,
                background: 'gray',
                '&:hover': {
                  backgroundColor: 'black',
                },
              }}
            >
              Reset Password
            </Button>
            <Typography variant="body2" sx={{ mt: 2 }}>
              <Link to="/" style={{ textDecoration: 'none' }}>Back to Login</Link>
            </Typography>
          </Box>
          {loading && (
            <Box sx={{
              position: 'fixed',
              top: 0,
              left: 0,
              width: '100vw',
              height: '100vh',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              bgcolor: 'white',
              zIndex: 9999
            }}>
              <l-trio
                size="50"
                speed="1.3"
                color="red"
              ></l-trio>
            </Box>
          )}

          <Snackbar
            open={snackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
          >
            <Alert
              onClose={handleSnackbarClose}
              severity={snackbarSeverity}
              sx={{
                width: '100%',

              }}
            >
              {snackbarMessage}
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </Container>
  );
};

export default ForgotPassword;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Typography,
    Grid,
    Select,
    CircularProgress,
    Snackbar,
    MenuItem,
    InputLabel, FormControl, ListSubheader
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import AddCustomer from '../Popupdialogbox/AddCustomer';







const FormWithTable = () => {
    const [rows, setRows] = useState([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: new Date().toISOString().split('T')[0], tax: '' }]);
    const [fullname, setFullname] = useState('');
    const [email, setEmail] = useState('');
    const [type, setType] = useState('');
    const [payamount, setPayamount] = useState('');
    const [customer, setCustomer] = useState('');
    const [address, setBillingAddress] = useState('');
    const [term, setTerm] = useState('due on receipt');
    const [invoicedate, setInvoiceDate] = useState(new Date().toISOString().split('T')[0]);
    const [duedate, setDueDate] = useState('');
    const [description, setDescription] = useState('');
    const [discount, setDiscount] = useState(0);
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [customers, setCustomers] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedCustomerData, setSelectedCustomerData] = useState({
        fullname: '',
        email: '',
        address: '',
    });

    useEffect(() => {
        const fetchCustomers = async () => {
            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/get-customer-data');
                setCustomers(response.data.customers);
            } catch (error) {
                console.error('Error fetching customers:', error);
            }
        };

        fetchCustomers();
    }, []);

    const handleOptionChange = (event) => {
        const selectedValue = event.target.value;
        setSelectedOption(selectedValue);

        if (selectedValue === 'Direct Customer') {
            setSelectedCustomerData({
                id: '',
                fullname: '',
                email: '',
                address: '',
            });
        } else {
            const selectedCustomer = customers.find((customer) => customer.id === selectedValue);
            if (selectedCustomer) {
                setSelectedCustomerData({
                    fullname: selectedCustomer.firstname,
                    email: selectedCustomer.email,
                    address: selectedCustomer.address,
                });
            }
        }
    };

    const handleAddRow = () => {
        const newRow = {
            id: rows.length + 1,
            product: '',
            quantity: '',
            rate: '',
            price: '',
            serviceDate: '',
            tax: ''
        };
        setRows([...rows, newRow]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, i) => {
            if (i === index) {
                const updatedRow = { ...row, [field]: value };
                if (field === 'product') {
                    updatedRow.rate = productPrices[value] || '';
                    updatedRow.price = (updatedRow.rate || 0) * (row.quantity || 0);
                }
                if (field === 'quantity' || field === 'rate') {
                    updatedRow.price = (updatedRow.rate || 0) * (updatedRow.quantity || 0);
                }
                return updatedRow;
            }
            return row;
        });

        setRows(newRows);

        if (field === 'product' && value !== '' && index === rows.length - 1) {
            handleAddRow();
        }
    };

    useEffect(() => {
        const invoiceDateObj = new Date(invoicedate);
        let daysToAdd = 0;

        switch (term) {
            case 'net 15':
                daysToAdd = 15;
                break;
            case 'net 30':
                daysToAdd = 30;
                break;
            case 'net 60':
                daysToAdd = 60;
                break;
            default:
                daysToAdd = 0;
        }

        invoiceDateObj.setDate(invoiceDateObj.getDate() + daysToAdd);
        setDueDate(invoiceDateObj.toISOString().split('T')[0]);
    }, [term, invoicedate]);

    const handleSubmit = async () => {
        setLoading(true);

        try {
            const data = {
                fullname: selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname,
                email: selectedOption === 'Direct Customer' ? email : selectedCustomerData.email,
                customer: selectedOption === 'Direct Customer' ? 'direct' : 'existing customer',
                address: selectedOption === 'Direct Customer' ? address : selectedCustomerData.address,
                invoicedate: invoicedate,
                type: type,
                description: description,
                items: rows.filter(row => row.product && row.quantity).map(row => ({
                    product: row.product,
                    quantity: row.quantity,
                    rate: row.rate,
                    price: row.price,
                    servicedate: row.servicedate,
                }))
            };

            {/**console.log('Data to be saved:', data);*/} // Check if data is correctly structured

            const response = await axios.post('https://admin.bizstor.lk/public/api/save-damage-data', data);
            console.log('return invoice saved successfully:', response.data);

            // Reset form fields and state after successful submission
            setFullname('');
            setEmail('');
            setType('');
            setPayamount('');
            setCustomer('');
            setBillingAddress('');
            setTerm('due on receipt');
            setInvoiceDate(new Date().toISOString().split('T')[0]);
            setDueDate(new Date().toISOString().split('T')[0]);
            setDescription('');
            setDiscount(0);
            setRows([{ id: 1, product: '', quantity: '', rate: '', price: '', servicedate: '', tax: '' }]);

            setAlertOpen(true); // Display success message
        } catch (error) {
            console.error('Error saving data:', error);
            // Handle error (e.g., display error message)
        } finally {
            setLoading(false);
        }
    };


    const calculateTotalAmount = () => {
        const total = rows.reduce((sum, row) => sum + parseFloat(row.price || 0), 0);
        return total;
    };

    const calculateTotalQuantity = () => {
        const total = rows.reduce((sum, row) => sum + parseFloat(row.quantity || 0), 0);
        return total;
    };



    const handleDirectCustomerChange = (field, value) => {
        if (field === 'fullname') {
            setFullname(value);
        } else if (field === 'email') {
            setEmail(value);
        } else if (field === 'address') {
            setBillingAddress(value);
        }
    };


    const handleCustomerTypeChange = (event) => {
        const type = event.target.value;
        setSelectedOption(type);
        setCustomer(type === 'Direct Customer' ? 'direct' : '');
    };
    const [products, setProducts] = useState([]);

    useEffect(() => {
        // Fetch product data from Laravel backend
        axios.get('https://admin.bizstor.lk/public/api/get-product-data')
            .then(response => {
                setProducts(response.data.products);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
            });
    }, []);

    const productPrices = {};
    products.forEach(product => {
        productPrices[product.product] = product.price;
    });

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                boxShadow: 1,
                padding: '20px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative',

            }}
        >
            <Typography variant="h6">
                <b>Invoice Returns</b>
            </Typography>
            <hr style={{ borderTop: '1px solid orange', width: '100%', margin: '10px 0' }} />

            <TableContainer>
                <Table

                    sx={{
                        marginBottom: '30px',
                    }}
                >
                    <TableHead>
                    </TableHead>
                    <TableBody>
                        <TableRow>
                            <TableCell style={{ width: '33%' }}>
                                <FormControl fullWidth variant="outlined" size="small">
                                    <InputLabel>Customer</InputLabel>
                                    <Select
                                        value={selectedOption}
                                        onChange={handleOptionChange}
                                        fullWidth
                                        label="Customer"
                                        variant="outlined"
                                        inputProps={{
                                            style: { minWidth: '100%' }, // Adjust min-width for Select
                                        }}
                                    >
                                        <MenuItem value="Direct Customer">Direct Customer</MenuItem>
                                        <AddCustomer />
                                        <ListSubheader>Existing Customers</ListSubheader>
                                        {customers.map((customer) => (
                                            <MenuItem key={customer.id} value={customer.id}>
                                                {customer.firstname} - {customer.email}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell style={{ width: '33%' }}>
                                <TextField
                                    label="Full Name"
                                    variant="outlined"
                                    value={selectedOption === 'Direct Customer' ? fullname : selectedCustomerData.fullname}
                                    onChange={(e) => handleDirectCustomerChange('fullname', e.target.value)}
                                    disabled={selectedOption !== 'Direct Customer'}
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                        style: { width: '100%' },
                                    }}
                                />
                            </TableCell>
                            <TableCell style={{ width: '33%' }}>
                                <TextField
                                    label="Email"
                                    variant="outlined"
                                    value={selectedOption === 'Direct Customer' ? email : selectedCustomerData.email}
                                    onChange={(e) => handleDirectCustomerChange('email', e.target.value)}
                                    disabled={selectedOption !== 'Direct Customer'}
                                    fullWidth
                                    size="small"
                                    InputProps={{
                                        style: { width: '100%' }, // Set width to 100% of parent cell
                                    }}
                                />
                            </TableCell>
                        </TableRow>



                        <TableRow>
                            <TableCell>
                                <TextField
                                    label="Billing Address"
                                    variant="outlined"
                                    value={selectedOption === 'Direct Customer' ? address : selectedCustomerData.address}
                                    onChange={(e) => handleDirectCustomerChange('address', e.target.value)}
                                    disabled={selectedOption !== 'Direct Customer'}
                                    fullWidth
                                    size="small"
                                    style={{ width: '100%' }} // Set width to 100% of parent cell

                                />
                            </TableCell>

                            <TableCell>
                                <Box>
                                    <FormControl variant="outlined" fullWidth size="small">
                                        <InputLabel id="type-label">Return Type</InputLabel>
                                        <Select
                                            labelId="type-label"
                                            id="type"
                                            value={type}
                                            onChange={(e) => setType(e.target.value)}
                                            label="Return Type"
                                            style={{ width: '100%' }} // Set width to 100% of parent cell

                                        >
                                            <MenuItem value="money back"><em>Money Back</em></MenuItem>
                                            <MenuItem value="replace">Replace</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Box>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    type="date"
                                    label="Invoice Date"
                                    value={invoicedate}
                                    onChange={(e) => setInvoiceDate(e.target.value)}
                                    variant="outlined"
                                    fullWidth
                                    size="small"
                                    style={{ width: '100%' }} // Set width to 100% of parent cell

                                />
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'right',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                        textAlign: 'right',
                    }}
                >
                    <Typography variant="subtitle1">
                        {type === 'money back' ? 'TOTAL' : 'QTY'}
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                        {type === 'money back' ? `Rs.-${calculateTotalAmount().toFixed(2)}` : calculateTotalQuantity()}
                    </Typography>
                </Box>

            </TableContainer>
            <TableContainer component={Paper} style={{ marginTop: '15px' }}>
                <Table size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Product</TableCell>
                            <TableCell>Qty</TableCell>
                            <TableCell>Rate</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Service Date</TableCell>
                            <TableCell>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row, index) => (
                            <TableRow key={row.id}>
                                <TableCell>{row.id}</TableCell>
                                <TableCell>
                                    <FormControl fullWidth>
                                        <Select
                                            value={row.product}
                                            onChange={(e) => handleInputChange(index, 'product', e.target.value)}
                                            fullWidth
                                            size="small"
                                            sx={{ width: '150px' }} // Adjusted height and width
                                        >
                                            {Object.keys(productPrices).map((product) => (
                                                <MenuItem key={product} value={product}>
                                                    {product}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={row.quantity}
                                        onChange={(e) => handleInputChange(index, 'quantity', e.target.value)}
                                        fullWidth
                                        size="small"
                                        sx={{ width: '150px' }} // Adjusted width and height
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={row.rate}
                                        onChange={(e) => handleInputChange(index, 'rate', e.target.value)}
                                        fullWidth
                                        size="small"
                                        sx={{ width: '100px' }} // Adjusted width
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="number"
                                        value={row.price}
                                        onChange={(e) => handleInputChange(index, 'price', e.target.value)}
                                        disabled
                                        fullWidth
                                        size="small"
                                        sx={{ width: '150px' }} // Adjusted width and height
                                    />
                                </TableCell>
                                <TableCell>
                                    <TextField
                                        type="date"
                                        value={row.servicedate}
                                        onChange={(e) => handleInputChange(index, 'servicedate', e.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        fullWidth
                                        size="small"
                                        sx={{ width: '150px' }}
                                    />
                                </TableCell>

                                <TableCell>
                                    <IconButton
                                        onClick={() => handleDeleteRow(index)}
                                        size="small"
                                        sx={{
                                            '&:hover': {
                                                color: 'red',
                                            },
                                        }}
                                    >
                                        <DeleteIcon fontSize="small" />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>

                </Table>
            </TableContainer>


            <Box display="flex" justifyContent="flex-end" marginTop="15px">
                <Button variant="contained" color="primary" onClick={handleAddRow} size="small" style={{ backgroundColor: 'black', color: 'white' }}
                >
                    Add Line
                </Button>
            </Box>

            <Grid container spacing={1} style={{ marginTop: '15px' }}>
                <Grid item xs={6}>
                    <TextField
                        label="Description"
                        value={description}
                        onChange={(e) => setDescription(e.target.value)}
                        variant="outlined"
                        fullWidth
                        multiline  // Enable multiline input
                        rows={4}  // Adjust the number of rows as needed
                        size="small"
                    />
                </Grid>
            </Grid>

            {loading && (
                <Box sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    width: '100vw',
                    height: '100vh',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    bgcolor: 'white',
                    zIndex: 9999
                }}>
                    <l-trio
                        size="50"
                        speed="1.3"
                        color="red"
                    ></l-trio>
                </Box>
            )}


            <Box display="flex" justifyContent="flex-end" marginTop="15px">
                <Button variant="contained" color="primary" onClick={handleSubmit} disabled={loading} size="small" style={{ backgroundColor: 'black', color: 'white' }}>

                    Submit
                </Button>
               
            </Box>

            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                message="return invoice saved successfully!"
            />
        </Box>
    );
};

export default FormWithTable;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    CircularProgress,
    Typography,
    Collapse,
    IconButton,
    TablePagination,
    Snackbar,
    Checkbox,
    Menu,
    MenuItem,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import PaymentsIcon from '@mui/icons-material/Payments';
import MakePayment from '../Popupdialogbox/Makepayment';

const productPrices = {
    "DOT4 250ml (White)": 850,
    "DOT3 250ml (Red)": 580,
    "DOT3 250ml (White)": 580,
    "DOT3 100ml (Red)": 290,
    "DOT3 100ml (White)": 290,
    "DOT3 50ml (Red)": 170,
    "DOT3 50ml (White)": 170,
    "Green 1L": 750,
    "4L + 4 Water(L)": 1800,
    "4L Pure": 1350
};

const InvoiceTable = () => {
    const [fetchedData, setFetchedData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [openRow, setOpenRow] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [deleteMessage, setDeleteMessage] = useState('');
    const [alertOpen, setAlertOpen] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]);
    const [anchorEl, setAnchorEl] = useState(null);
    const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
    const [paymentDialogOpen, setPaymentDialogOpen] = useState(false);
    const [selectedOrder, setSelectedOrder] = useState(null);

    const fetchData = async () => {
        setLoading(true);
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
            setFetchedData(response.data);
        } catch (error) {
            console.error('Error fetching data:', error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    const handleRowClick = (index) => {
        setOpenRow(openRow === index ? null : index);
    };

    const handleRowSelect = (event, orderId) => {
        const selectedIndex = selectedRows.indexOf(orderId);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selectedRows, orderId);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selectedRows.slice(1));
        } else if (selectedIndex === selectedRows.length - 1) {
            newSelected = newSelected.concat(selectedRows.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selectedRows.slice(0, selectedIndex),
                selectedRows.slice(selectedIndex + 1),
            );
        }

        setSelectedRows(newSelected);
    };

    const isSelected = (orderId) => selectedRows.indexOf(orderId) !== -1;

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const calculateTotalAmount = (items) => {
        return items.reduce((total, item) => total + item.quantity * getPriceForProduct(item.product), 0);
    };

    const getPriceForProduct = (productName) => {
        return productPrices[productName] || 0;
    };

    const handleDelete = async () => {
        setLoading(true);
        try {
            const promises = selectedRows.map(async (id) => {
                const response = await axios.delete(`https://admin.bizstor.lk/public/api/delete-data/${id}`);
                return response.status === 200;
            });
            const results = await Promise.all(promises);

            if (results.every((result) => result)) {
                setFetchedData(fetchedData.filter(order => !selectedRows.includes(order.id)));
                setDeleteMessage('Selected orders deleted successfully.');
                setAlertOpen(true);
                setSelectedRows([]);
                setDeleteConfirmationOpen(false);
            } else {
                setDeleteMessage('Failed to delete selected orders.');
            }
        } catch (error) {
            console.error('Error deleting data:', error);
            setDeleteMessage('Error deleting orders.');
        } finally {
            setLoading(false);
        }
    };

    const clearDeleteMessage = () => {
        setDeleteMessage('');
    };

    const handleMenuOpen = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    const handleOpenDeleteConfirmation = () => {
        setDeleteConfirmationOpen(true);
    };

    const handleCloseDeleteConfirmation = () => {
        setDeleteConfirmationOpen(false);
    };

    const handleOpenPaymentDialog = (order) => {
        setSelectedOrder(order);
        setPaymentDialogOpen(true);
    };

    const handleClosePaymentDialog = () => {
        setPaymentDialogOpen(false);
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                width: '100%',
                maxWidth: '1200px',
                margin: '0 auto',
                boxShadow: 1,
                padding: '20px',
                borderRadius: '5px',
                bgcolor: 'background.paper',
                marginTop: '30px',
                position: 'relative'
            }}
        >
            <Typography variant="h5">
                Invoice Report
                <hr />
            </Typography>
            <Snackbar
                open={alertOpen}
                autoHideDuration={6000}
                onClose={() => setAlertOpen(false)}
                message="Deleted successfully!"
                anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            />
            <Dialog
                open={deleteConfirmationOpen}
                onClose={handleCloseDeleteConfirmation}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Are you sure you want to delete selected orders?"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleCloseDeleteConfirmation} color="primary">
                        No
                    </Button>
                    <Button onClick={handleDelete} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '10px' }}>
                <IconButton
                    aria-label="more"
                    aria-controls="long-menu"
                    aria-haspopup="true"
                    onClick={handleMenuOpen}
                    disabled={selectedRows.length === 0}
                >
                    <DeleteIcon />
                </IconButton>
                <Menu
                    id="long-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleMenuClose}
                >
                    <MenuItem onClick={handleOpenDeleteConfirmation}>Delete Selected Orders</MenuItem>
                </Menu>
            </Box>
            <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell padding="checkbox">
                                <Checkbox
                                    indeterminate={selectedRows.length > 0 && selectedRows.length < fetchedData.length}
                                    checked={selectedRows.length === fetchedData.length}
                                    onChange={() => {
                                        if (selectedRows.length === fetchedData.length) {
                                            setSelectedRows([]);
                                        } else {
                                            setSelectedRows(fetchedData.map((order) => order.id));
                                        }
                                    }}
                                />
                            </TableCell>
                            <TableCell><b>Full Name</b></TableCell>
                            <TableCell><b>Customer</b></TableCell>
                            <TableCell><b>Term</b></TableCell>
                            <TableCell><b>Invoice Date</b></TableCell>
                            <TableCell><b>Due Date</b></TableCell>
                            <TableCell><b>Type</b></TableCell>
                            <TableCell><b>Total Amount</b></TableCell>
                            <TableCell><b>Status</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {loading ? (
                            <TableRow>
                                <TableCell colSpan={8}>
                                    <Box sx={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
                                        <CircularProgress />
                                    </Box>
                                </TableCell>
                            </TableRow>
                        ) : (
                            fetchedData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((order, index) => (
                                <React.Fragment key={order.id}>
                                    <TableRow
                                        onClick={() => handleRowClick(index)}
                                        sx={{ cursor: 'pointer' }}
                                        selected={isSelected(order.id)}
                                        aria-checked={isSelected(order.id)}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={isSelected(order.id)}
                                                onChange={(event) => handleRowSelect(event, order.id)}
                                            />
                                        </TableCell>
                                        <TableCell>{order.fullname}</TableCell>
                                        <TableCell>{order.customer}</TableCell>
                                        <TableCell>{order.term}</TableCell>
                                        <TableCell>{order.invoice_date}</TableCell>
                                        <TableCell>{order.due_date}</TableCell>
                                        <TableCell>{order.type}</TableCell>
                                        <TableCell>{calculateTotalAmount(order.items)}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleOpenPaymentDialog(order)}
                                            >
                                                <PaymentsIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                    <TableRow>
                                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={9}>
                                            <Collapse in={openRow === index} timeout="auto" unmountOnExit>
                                                <Box sx={{ margin: 1 }}>
                                                    <Typography variant="h6" gutterBottom component="div">
                                                        Order Details
                                                    </Typography>
                                                    <Table size="small">
                                                        <TableHead>
                                                            <TableRow>
                                                                <TableCell><b>Product</b></TableCell>
                                                                <TableCell><b>Quantity</b></TableCell>
                                                                <TableCell><b>Price</b></TableCell>
                                                                <TableCell><b>Total Amount</b></TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            {order.items.map((item, itemIndex) => (
                                                                <TableRow key={itemIndex}>
                                                                    <TableCell>{item.product}</TableCell>
                                                                    <TableCell>{item.quantity}</TableCell>
                                                                    <TableCell>{item.rate}</TableCell>
                                                                    <TableCell>{item.quantity * (item.rate)}</TableCell>
                                                                </TableRow>
                                                            ))}
                                                            <TableRow>
    <TableCell colSpan={3} align="right"><b>Grand Total:</b></TableCell>
    <TableCell>
      {/* Calculate the grand total */}
      {order.items.reduce((acc, item) => acc + (item.quantity * item.rate), 0)}
    </TableCell>
  </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </Box>
                                            </Collapse>
                                        </TableCell>
                                    </TableRow>
                                </React.Fragment>
                            ))
                        )}
                    </TableBody>
                </Table>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={fetchedData.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </TableContainer>
            <Dialog open={paymentDialogOpen} onClose={handleClosePaymentDialog}>
                <DialogTitle>Make Payment</DialogTitle>
                <DialogContent>
                    <MakePayment order={selectedOrder} />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClosePaymentDialog} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClosePaymentDialog} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default InvoiceTable;

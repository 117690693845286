import React, { useEffect, useState } from 'react';
import axios from 'axios';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import { Box } from '@mui/system';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';

const Chart3 = ({ onTotalAmountChange }) => {
  const [user, setUser] = useState({});
  const [token, setToken] = useState(() => localStorage.getItem('token') || null);
  const [fetchedData, setFetchedData] = useState([]);
  const [fetchedExpenses, setFetchedExpenses] = useState([]);
  const [fetchedDamageData, setFetchedDamageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [dayByDayData, setDayByDayData] = useState([]);

  useEffect(() => {
    if (token) {
      localStorage.setItem('token', token);
    }
  }, [token]);

  useEffect(() => {
    const fetchProfile = async () => {
      if (!token) return; // Exit if no token

      try {
        const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUser(response.data);
      } catch (error) {
        console.log('');
      }
    };

    fetchProfile();
  }, [token]);

  useEffect(() => {
    if (user.id) {
      const fetchData = async () => {
        setLoading(true);
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-data');
          if (user.id) {

            // Filter orders based on user.id
            const nisalOrders = response.data.filter(order =>
              order.usertoken.toLowerCase() === user.id.toString().toLowerCase()
            );

            setFetchedData(nisalOrders);
          }
        } catch (error) {
          console.error('Error fetching data:', error);
        } finally {
          setLoading(false);
        }
      };

      const fetchExpenses = async () => {
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
          if (user.id) {

            // Filter expenses based on user.id
            const nisalOrders = response.data.filter(expense =>
              expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
            );

            setFetchedExpenses(nisalOrders);
          }
        } catch (error) {
          console.error('Error fetching expenses data:', error);
        }
      };

      const fetchDamageData = async () => {
        try {
          const response = await axios.get('https://admin.bizstor.lk/public/api/get-damage-data');
          if (user.id) {

            // Filter damages based on user.id
            const nisalOrders = response.data.filter(damage =>
              damage.usertoken.toLowerCase() === user.id.toString().toLowerCase() &&
              damage.type === 'money back'
            );

            setFetchedDamageData(nisalOrders);
          }
        } catch (error) {
          console.error('Error fetching damage data:', error);
        }
      };

      fetchData();
      fetchExpenses();
      fetchDamageData();
    }
  }, [user.id]);

  useEffect(() => {
    if (!fetchedData.length && !fetchedExpenses.length && !fetchedDamageData.length) return;
  
    // Get current date and calculate the start of the week (Monday)
    const now = new Date();
    const currentDay = now.getDay(); // Sunday is 0, Monday is 1, etc.
    const startOfWeek = new Date(now);
    startOfWeek.setDate(now.getDate() - (currentDay === 0 ? 6 : currentDay - 1)); // Set to Monday of this week
    startOfWeek.setHours(0, 0, 0, 0); // Ensure the start time is at midnight
  
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6); // Set to Sunday of this week
    endOfWeek.setHours(23, 59, 59, 999); // Ensure the end time is at the last moment of Sunday
  
    // Initialize arrays to store totals for each day of the week
    const salesTotals = Array(7).fill(0);
    const expenseTotals = Array(7).fill(0);
    const damageTotals = Array(7).fill(0);
  
    // Helper function to determine the day index (0 for Monday, 6 for Sunday)
    const getDayIndex = (date) => {
      const dayOfWeek = date.getDay(); // 0 for Sunday, 6 for Saturday
      return dayOfWeek === 0 ? 6 : dayOfWeek - 1; // Adjust so that Monday is 0, Sunday is 6
    };
  
    // Process fetched data for sales
    fetchedData.forEach(order => {
      const createdAt = new Date(order.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);
        let orderTotal = 0;
  
        order.items.forEach(item => {
          const itemPrice = item.price;
          const itemTax = item.tax || 0;
          const itemTotal = itemPrice + (itemPrice * (itemTax / 100));
          orderTotal += itemTotal;
        });
  
        const discountPercentage = order.discount || 0;
        const discountAmount = (orderTotal * discountPercentage) / 100;
        orderTotal -= discountAmount;
  
        salesTotals[dayIndex] += orderTotal;
      }
    });
  
    // Process fetched data for expenses
    fetchedExpenses.forEach(expense => {
      const createdAt = new Date(expense.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);
  
        expense.items.forEach(item => {
          expenseTotals[dayIndex] += item.amount;
        });
      }
    });
  
    // Process fetched data for damages
    fetchedDamageData.forEach(damage => {
      const createdAt = new Date(damage.created_at);
      if (createdAt >= startOfWeek && createdAt <= endOfWeek) {
        const dayIndex = getDayIndex(createdAt);
  
        damage.items.forEach(item => {
          damageTotals[dayIndex] += item.price;
        });
      }
    });
  
    // Map the totals to the days of the week
    const daysOfWeek = ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'];
  
    const data = daysOfWeek.map((day, index) => ({
      name: day,
      sellAmount: salesTotals[index],
      expenseAmount: expenseTotals[index],
      returnAmount: damageTotals[index],
    }));
  
    setDayByDayData(data);
  
    if (onTotalAmountChange) {
      onTotalAmountChange(data);
    }
  }, [fetchedData, fetchedExpenses, fetchedDamageData, onTotalAmountChange]);
    
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        margin: '0 auto',
        boxShadow: 1,
        padding: 'auto',
        borderRadius: '5px',
        bgcolor: 'background.paper',
      }}
    >
      {loading ? (
        <Box sx={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          bgcolor: 'white',
          zIndex: 9999
        }}>
          <l-trio
            size="50"
            speed="1.3"
            color="red"
          ></l-trio>
        </Box>
      ) : (
        <Box>
          <Typography variant="h6" gutterBottom>
            Sales and Expenses
          </Typography>

          <ResponsiveContainer width="100%" height={250}>
            <LineChart
              width={1000}
              height={700}
              data={dayByDayData}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Line type="monotone" dataKey="sellAmount" stroke="#8884d8" strokeWidth={2} />
              <Line type="monotone" dataKey="expenseAmount" stroke="red" strokeWidth={2} />
              <Line type="monotone" dataKey="returnAmount" stroke="#FFB300" strokeWidth={2} />
            </LineChart>
          </ResponsiveContainer>
        </Box>
      )}
    </Box>
  );
}

export default Chart3;

import { useState, useEffect } from 'react';
import axios from 'axios';

const Expenses = ({ onTotalExpensesChange }) => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);

    const [fetchedData, setFetchedData] = useState([]);
    const [totalExpenses, setTotalExpenses] = useState(0);

    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; // Exit if no token

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
        }
    }, [user.id]);


    const fetchData = async () => {
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-expense-data');
            if (user.id) {
                {/**console.log('console.log("");:', user.id);*/}
                const Expenses = response.data.filter(expense =>
                    expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setFetchedData(Expenses);
            }
        } catch (error) {
            console.error('Error fetching expenses data:', error);
        }
    };

    useEffect(() => {
        fetchData();
    }, []);

    useEffect(() => {
        let total = 0;
        fetchedData.forEach(expense => {
            expense.items.forEach(item => {
                total += item.amount;
            });
        });
        setTotalExpenses(total);
        if (onTotalExpensesChange) {
            onTotalExpensesChange(total);
        }
    }, [fetchedData, onTotalExpensesChange]);

    // Return null to render nothing in the component
    return null;
};

export default Expenses;

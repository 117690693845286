import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    TextField,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Box,
    IconButton,
    Typography,
    Select,
    MenuItem,
    Grid,
    Snackbar,
    FormControl,
    InputLabel,
    ListSubheader,
    Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import { Link } from 'react-router-dom';
import { quantum, trio } from 'ldrs';
import { styled, keyframes } from '@mui/system';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import HomeIcon from '@mui/icons-material/Home';

const slideInFromLeft = keyframes`
  0% {
    transform: translateX(-20px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
`;

const AnimatedTypography = styled(Typography)(({ theme }) => ({
    animation: `${slideInFromLeft} 0.5s ease-out`,
}));

quantum.register();
trio.register();

const Expenses = () => {
    const [user, setUser] = useState({});
    const [token, setToken] = useState(() => localStorage.getItem('token') || null);
    const [rows, setRows] = useState([{ category: '', amount: '' }]);
    const [fullname, setFullName] = useState('');
    const [phone, setPhone] = useState('');
    const [type, setType] = useState('');
    const [description, setDescription] = useState('');
    const [date, setDate] = useState('');
    const [loading, setLoading] = useState(false);
    const [alertOpen, setAlertOpen] = useState(false);
    const [expenses, setExpenses] = useState([]);
    const [customers, setCustomers] = useState([]);
    const [suppliers, setSuppliers] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false); 
    const [snackbarMessage, setSnackbarMessage] = useState(''); 
    const [snackbarSeverity, setSnackbarSeverity] = useState('success'); 

    const handleSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };

    const handleSnackbarClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };


    useEffect(() => {
        if (token) {
            localStorage.setItem('token', token);
        }
    }, [token]);

    useEffect(() => {
        const fetchProfile = async () => {
            if (!token) return; 

            try {
                const response = await axios.get('https://admin.bizstor.lk/public/api/profile', {
                    headers: {
                        Authorization: `Bearer ${token}`,
                    },
                });
                setUser(response.data);
            } catch (error) {
                console.log('');
            }
        };

        fetchProfile();
    }, [token]);

    useEffect(() => {
        if (user.id) {
            fetchData();
            fetchCustomers();
            fetchSuppliers();
        }
    }, [user.id]);

    const handleAddRow = () => {
        setRows([...rows, { category: '', amount: '' }]);
    };

    const handleDeleteRow = (index) => {
        const newRows = rows.filter((row, i) => i !== index);
        setRows(newRows);
    };

    const handleInputChange = (index, field, value) => {
        const newRows = rows.map((row, i) => {
            if (i === index) {
                return { ...row, [field]: value };
            }
            return row;
        });
        setRows(newRows);
    };

    const fetchData = () => {
        setLoading(true);

        axios.get('https://admin.bizstor.lk/public/api/get-expenses-category-data')
            .then(response => {
                if (user.id) {
                    const Expenses = response.data.expenses.filter(expense =>
                        expense.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                    );
                    setExpenses(Expenses);
                }

                setLoading(false);
            })
            .catch(error => {
                console.error('Error fetching product data:', error);
                setLoading(false);
            });

    };

    const fetchCustomers = async () => {
        setLoading(true); 
        try {
            const response = await axios.get('https://admin.bizstor.lk/public/api/get-customer-data');
            if (user.id) {
                const Customers = response.data.customers.filter(customer =>
                    customer.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setCustomers(Customers);

            }
        } catch (error) {
            console.error('Error fetching customers:', error);
        } finally {
            setLoading(false); 
        }
    };

    useEffect(() => {
        fetchCustomers();
    }, []);


    const fetchSuppliers = async () => {
        try {
            setLoading(true);

            const response = await axios.get('https://admin.bizstor.lk/public/api/suppliers');
            if (user.id) {
                const Supplier = response.data.filter(supplier =>
                    supplier.usertoken.toLowerCase() === user.id.toString().toLowerCase()
                );
                setSuppliers(Supplier);
            }

        } catch (error) {
            console.error('Error fetching suppliers:', error);
        } finally {
            setLoading(false); 
        }
    };

    useEffect(() => {
        fetchSuppliers();
    }, []);

    useEffect(() => {
        fetchData();
    }, []);

    const handleSelectChange = (event) => {
        const selectedValue = event.target.value;

        const selectedCustomer = customers.find(customer => customer.firstname === selectedValue);
        const selectedSupplier = suppliers.find(supplier => supplier.supplier_name === selectedValue);

        if (selectedCustomer) {
            setFullName(selectedCustomer.firstname);
            setPhone(selectedCustomer.phone || ''); 
        } else if (selectedSupplier) {
            setFullName(selectedSupplier.supplier_name);
            setPhone(''); 
        }

        setType(selectedValue);
    };

    const handleSubmit = async () => {
        setLoading(true);

        const hasInvalidRow = rows.some(row => !row.category || !row.amount);

        if (hasInvalidRow) {
            handleSnackbar('Please fill in all required category and amount fields.', 'error');
            setLoading(false);
            return;
        }

        try {
            const data = {
                usertoken: user.id,
                fullname: fullname,
                phone: phone,
                type: type,
                date: date || new Date().toISOString().split('T')[0],
                description: description,
                items: rows.map(row => ({
                    category: row.category,
                    amount: row.amount,
                }))
            };

            {/**console.log('Data to be sent:', data);*/}

            const response = await axios.post('https://admin.bizstor.lk/public/api/save-expense-data', data);
            {/**console.log('Data saved successfully:', response.data);*/}

            setFullName('');
            setPhone('');
            setType('');
            setDate('');
            setDescription('');
            setRows([{ category: '', amount: '' }]);

            handleSnackbar('Expenses save successfully!', 'success');
            setLoading(false);
            fetchData();
        } catch (error) {
            console.error('Error saving data:', error);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Box>
                    <AnimatedTypography
                component="h1"
                sx={{
                    ml: { xs: 2, sm: 3 }, // Adjust margins for responsiveness
                    mt: { xs: 2, sm: 3 },
                    color: '',
                    letterSpacing: '0.01em',
                    fontSize: { xs: '20px', sm: '24px' }, // Responsive font size
                }}
            >
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, 
                            fontSize: { xs: '14px', sm: '16px' },
                            fontWeight: 'bold',
                            color: '',
                        }}
                    >
                        Expenses
                    </Typography>
                    {/* Hide separator, icon, and text on small screens */}
                    <Typography
                        component="span"
                        sx={{
                            display: { xs: 'none', sm: 'inline' }, // Hidden on xs
                            ml: 1,
                            fontSize: '14px',
                            color: '',
                        }}
                    >
                        |
                    </Typography>
                    <HomeIcon
                        sx={{
                            ml: 1,
                            fontSize: '18px',
                            color: '#6d7081',
                        }}
                    />
                    <Typography
                        component="span"
                        sx={{
                            ml: 0.5,
                            fontSize: '14px',
                            color: '#6d7081',
                        }}
                    >
                        - Expenses 
                    </Typography>
                </Box>
            </AnimatedTypography>
            {/**<AnimatedTypography
                component="h1"
                sx={{
                    ml: 3,
                    mt: 3,
                    fontSize: '1rem',      
                    color: 'secondary.main',
                    letterSpacing: '0.01em' ,
                }}
            >
                Dashboard / Expenses
            </AnimatedTypography>*/}
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    width: '95%',
                    maxWidth: 'auto',
                    margin: 'auto',
                    boxShadow: '0 4px 20px rgba(0, 0, 0, 0.1)',
                    padding: '10px',
                    borderRadius: '5px',
                    bgcolor: 'background.paper',
                    marginTop: '30px',
                    position: 'relative',
                    marginBottom: '20px'
                }}
            >
                <Typography variant="h5"
                    sx={{
                        ml: 2
                    }}
                >
                    
                </Typography>
                <Box
                        sx={{
                            display: 'flex',
                            alignItems: 'center', // Align items vertically
                            '@media (max-width: 600px)': {
                              margin: 'auto',
                            },
                            color: '#6d7081',
                            ml:2
                        }}
                    >
                        <h2>EXPENSES</h2>
                    </Box> 
                <TableContainer>
                    <Table>
                        <TableBody>
                            <TableRow>
                                <TableCell colSpan={4}>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} sm={6} md={3}>
                                            <FormControl variant="outlined" fullWidth size="small">
                                                <InputLabel>Select Person</InputLabel>
                                                <Select
                                                    value={type}
                                                    onChange={handleSelectChange}
                                                    label="Select Person"
                                                    fullWidth
                                                >
                                                    <ListSubheader>Customers</ListSubheader>
                                                    {customers.length > 0 && customers.map(customer => (
                                                        <MenuItem key={customer.id} value={customer.firstname}>
                                                            {customer.firstname}
                                                        </MenuItem>
                                                    ))}
                                                    <ListSubheader>Suppliers</ListSubheader>
                                                    {suppliers.map(supplier => (
                                                        <MenuItem key={supplier.id} value={supplier.supplier_name}>
                                                            {supplier.supplier_name}
                                                        </MenuItem>
                                                    ))}
                                                    <MenuItem value="">
                                                        <Link to="/addcustomer" style={{ textDecoration: 'none', color: 'inherit', }}>
                                                            Add New Customer
                                                        </Link>
                                                </MenuItem>
                                                <MenuItem value="">
                                                        <Link to="/addsupplier" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                            Add New Supplier
                                                        </Link>
                                                </MenuItem>
                                                </Select>
                                            </FormControl>
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                label="Full Name"
                                                value={fullname}
                                                onChange={(e) => setFullName(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                label="Phone"
                                                value={phone}
                                                onChange={(e) => setPhone(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                            />
                                        </Grid>

                                        <Grid item xs={12} sm={6} md={3}>
                                            <TextField
                                                type="date"
                                                label="Date"
                                                value={date || new Date().toISOString().split('T')[0]}
                                                onChange={(e) => setDate(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                InputLabelProps={{
                                                    shrink: true,
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>
                            </TableRow>

                            <TableRow>
                                <TableCell colSpan={2}>
                                    <Grid container justifyContent="flex-start">
                                        <Grid item xs={12} md={12}>
                                            <TextField
                                                label="Description"
                                                value={description}
                                                onChange={(e) => setDescription(e.target.value)}
                                                variant="outlined"
                                                fullWidth
                                                size="small"
                                                multiline
                                                minRows={4}
                                            />
                                        </Grid>
                                    </Grid>
                                </TableCell>

                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'right',
                        justifyContent: 'flex-end',
                        marginTop: '10px',
                        textAlign: 'right',
                    }}
                >
                    <Typography variant="subtitle1">
                        TOTAL
                    </Typography>
                    <Typography variant="h4" fontWeight="bold">
                        LKR {rows.reduce((sum, row) => sum + parseFloat(row.amount || 0), 0).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}                    </Typography>
                </Box>

                {/**   <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'flex-end',
                    padding: '10px',
                    marginTop: '10px',
                }}
            >
                <Typography
                    sx={{
                        fontWeight: 'bold',
                        fontSize: '1.25rem', // Adjust size as needed
                    }}
                >
                    Total : LKR {rows.reduce((sum, row) => sum + parseFloat(row.amount || 0), 0).toFixed(2)}
                </Typography>
            </Box>*/}

                <TableContainer component={Paper} style={{ marginTop: '20px', boxShadow: '0px 0px 10px rgba(0, 0, 0, 0.1)' }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>ID</TableCell>
                                <TableCell>Category</TableCell>
                                <TableCell>Amount</TableCell>
                                <TableCell>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {rows.map((row, index) => (
                                <TableRow key={index}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>
                                        <FormControl variant="outlined" fullWidth size="small">
                                            <InputLabel>Category</InputLabel>
                                            <Select
                                                value={row.category}
                                                onChange={(e) => handleInputChange(index, 'category', e.target.value)}
                                                label="Category"
                                                fullWidth
                                                sx={{
                                                    width: '300px',
                                                    '@media (max-width: 600px)': {
                                                width: '200px',
                                            },
                                                }}
                                            >
                                                {expenses.length > 0 && expenses.map(expense => (
                                                    <MenuItem key={expense.id} value={expense.expenses}>
                                                        {expense.expenses}
                                                    </MenuItem>
                                                ))}
                                                <hr></hr>
                                                <MenuItem value="">
                                                    <Link to="/addexpenses" style={{ textDecoration: 'none', color: 'inherit' }}>
                                                        Add New Expense
                                                    </Link>
                                                </MenuItem>
                                            </Select>
                                        </FormControl>
                                    </TableCell>
                                    <TableCell>
                                        <TextField
                                            label="Amount"
                                            value={row.amount}
                                            onChange={(e) => handleInputChange(index, 'amount', e.target.value)}
                                            variant="outlined"
                                            fullWidth
                                            size="small"
                                            sx={{
                                                width: '200px'
                                            }}
                                            inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <IconButton onClick={() => handleDeleteRow(index)}>
                                            <DeleteIcon
                                                sx={{
                                                    '&:hover': {
                                                        color: 'red',
                                                    },
                                                }} />
                                        </IconButton>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '10px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={handleAddRow}
                        style={{ backgroundColor: 'black', color: 'white' }}
                    >
                        <PlaylistAddIcon/>
                    </Button>
                </Box>



                <Box style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '20px' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleSubmit}
                        style={{
                            backgroundColor: '#4287f5',
                            color: 'white',
                            marginTop: '30px',
                            borderRadius: '20px',
                            padding: '8px 24px',
                            '&:hover': {
                                backgroundColor: '#356bc4',
                            },
                            marginBottom: { xs: '8px', sm: '0' }
                        }}
                        value='save'

                    >
                        Submit
                    </Button>
                </Box>

                {loading && (
                    <Box sx={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100vw',
                        height: '100vh',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'white', 
                        zIndex: 9999
                    }}>
                        <l-trio
                            size="50"
                            speed="1.3"
                            color="red"
                        ></l-trio>
                    </Box>
                )}

                <Snackbar
                    open={snackbarOpen}
                    autoHideDuration={6000}
                    onClose={handleSnackbarClose}
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                >
                    <Alert
                        onClose={handleSnackbarClose}
                        severity={snackbarSeverity}
                        sx={{
                            width: '100%',

                        }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Box>
        </Box>
    );
};

export default Expenses;
